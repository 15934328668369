import { Button } from "@chakra-ui/react";
import classes from "./TeddyModal.module.css";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Teddy from "../../../components/Teddy";
import { svg2png } from "../../../utils/svg2png";
import { Backdrop } from "../../../components/Modal";

const downloadTeddyPNG = (png: string) => {
  const downloadEl = document.createElement("a");
  downloadEl.href = png;
  downloadEl.download = "teddy.png";
  downloadEl.click();
};

const TeddyModal: React.FC<{ onDismiss: () => void; svg: string }> = (props) => {
  const { onDismiss, svg } = props;

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [png, setPng] = useState<string | null>();

  const isMobile: boolean = width <= 991;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    const loadPng = async () => {
      setPng(await svg2png(svg, 320, 320));
    };
    loadPng();

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [svg]);

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop
          onDismiss={() => {
            onDismiss();
          }}
        />,
        document.getElementById("backdrop-root")!
      )}
      {ReactDOM.createPortal(
        <div className={classes.modal}>
          {png && (
            <Teddy
              imgPath={png}
              alt="teddy"
              className={classes.teddyImg}
              wrapperClassName={classes.teddyWrapper}
            />
          )}
          <div className={classes.displayTeddyFooter}>
            {!isMobile && png && (
              <Button
                onClick={() => {
                  downloadTeddyPNG(png);
                }}
                variant="brand"
              >
                Download
              </Button>
            )}
          </div>
        </div>,
        document.getElementById("overlay-root")!
      )}
    </>
  );
};
export default TeddyModal;

import { Auction } from "../../wrappers/teddyAuction";
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import classes from "./AuctionActivity.module.css";
import bidHistoryClasses from "./BidHistory.module.css";
import Bid from "../Bid";
import AuctionTimer from "../AuctionTimer";
import CurrentBid from "../CurrentBid";
import Winner from "../Winner";
import BidHistory from "../BidHistory";
import AuctionNavigation from "../AuctionNavigation";
import AuctionActivityWrapper from "../AuctionActivityWrapper";
import AuctionTitleAndNavWrapper from "../AuctionTitleAndNavWrapper";
import AuctionActivityTeddyTitle from "../AuctionActivityTeddyTitle";
import AuctionActivityDateHeadline from "../AuctionActivityDateHeadline";
import BidHistoryBtn from "../BidHistoryBtn";
import config from "../../config";
import { buildEtherscanAddressLink } from "../../utils/etherscan";
import TeddyInfoCard from "../TeddyInfoCard";
import { useAppSelector } from "../../hooks";
import BidHistoryModal from "../BidHistoryModal";
import { Box, useForceUpdate } from "@chakra-ui/react";

const openEtherscanBidHistory = () => {
  const url = buildEtherscanAddressLink(
    config.addresses.teddyAuctionHouseProxy
  );
  window.open(url);
};

interface AuctionActivityProps {
  auction: Auction;
  isFirstAuction: boolean;
  isLastAuction: boolean;
  onPrevAuctionClick: () => void;
  onNextAuctionClick: () => void;
  displayGraphDepComps: boolean;
}

const AuctionActivity: React.FC<AuctionActivityProps> = (
  props: AuctionActivityProps
) => {
  const {
    auction,
    isFirstAuction,
    isLastAuction,
    onPrevAuctionClick,
    onNextAuctionClick,
    displayGraphDepComps,
  } = props;

  const isCool = useAppSelector((state) => state.application.isCoolBackground);

  const forceUpdate = useForceUpdate()

  const [auctionEnded, setAuctionEnded] = useState(false);
  const [auctionTimer, setAuctionTimer] = useState(false);

  const [showBidHistoryModal, setShowBidHistoryModal] = useState(false);
  const showBidModalHandler = () => {
    setShowBidHistoryModal(true);
  };
  const dismissBidModalHanlder = () => {
    setShowBidHistoryModal(false);
  };

  // timer logic - check auction status every 30 seconds, until five minutes remain, then check status every second
  useEffect(() => {
    if (!auction) return;

    const timeLeft = Number(auction.endTime) - Math.floor(Date.now() / 1000);

    if (auction && timeLeft <= 0) {
      setAuctionEnded(true);
    } else {
      setAuctionEnded(false);
      const timer = setTimeout(
        () => {
          setAuctionTimer(!auctionTimer);
        },
        timeLeft > 300 ? 30000 : 1000
      );

      return () => {
        clearTimeout(timer);
      };
    }
  }, [auctionTimer, auction]);

  if (!auction) return null;

  return (
    <>
      {showBidHistoryModal && (
        <BidHistoryModal onDismiss={dismissBidModalHanlder} auction={auction} />
      )}

      <AuctionActivityWrapper>
        <div className={classes.informationRow}>
          <Row className={classes.activityRow}>
            <AuctionTitleAndNavWrapper>
              {displayGraphDepComps ? (
                <AuctionNavigation
                  isFirstAuction={isFirstAuction}
                  isLastAuction={isLastAuction}
                  onNextAuctionClick={onNextAuctionClick}
                  onPrevAuctionClick={onPrevAuctionClick}
                />
              ) : (
                <div />
              )}
              <AuctionActivityDateHeadline startTime={auction.startTime} />
            </AuctionTitleAndNavWrapper>
            <Col lg={12}>
              <AuctionActivityTeddyTitle
                isCool={isCool}
                teddyId={auction.teddyId}
              />
            </Col>
          </Row>
          <Row className={classes.activityRow}>
            <Col lg={4} className={classes.currentBidCol}>
              <CurrentBid
                currentBid={auction.amount}
                auctionEnded={auctionEnded}
              />
            </Col>
            <Col
              lg={6}
              className={classes.auctionTimerCol}
              style={{ minHeight: `69px` }}
            >
              {auctionEnded ? (
                isLastAuction ? (
                  <Winner winner={auction.bidder} teddyId={auction.teddyId} />
                ) : (
                  <Winner winner={auction.bidder} teddyId={auction.teddyId} />
                )
              ) : (
                <AuctionTimer auction={auction} auctionEnded={auctionEnded} />
              )}
            </Col>
          </Row>
        </div>
        {isLastAuction ? (
          <>
            <Row className={classes.activityRow}>
              <Col lg={12}>
                <Bid
                  auction={auction}
                  auctionEnded={auctionEnded}
                  onBidSuccess={forceUpdate}
                />
              </Col>
            </Row>
          </>
        ) : (
          <div />
        )}
        <Row className={classes.activityRow}>
          <Col lg={12}>
          <Box>
              {!isLastAuction ? (
                <TeddyInfoCard
                  teddyId={auction.teddyId.toNumber()}
                  bidHistoryOnClickHandler={showBidModalHandler}
                />
              ) : (
                displayGraphDepComps && (
                    <BidHistory
                      auctionId={auction.teddyId.toString()}
                      max={3}
                      classes={bidHistoryClasses}
                    />
                )
              )}
            </Box>

            {/* If no bids, show nothing. If bids avail:graph is stable? show bid history modal,
            else show etherscan contract link */}
            <Box>
              {isLastAuction &&
                !auction.amount.eq(0) &&
                (displayGraphDepComps ? (
                  <BidHistoryBtn onClick={showBidModalHandler} />
                ) : (
                  <BidHistoryBtn onClick={openEtherscanBidHistory} />
                ))}
            </Box>
          </Col>
        </Row>
      </AuctionActivityWrapper>
    </>
  );
};

export default AuctionActivity;

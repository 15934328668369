import { ChainId, useCall, useContractCall, useContractFunction, useEthers } from "@usedapp/core";
import { BigNumber as EthersBN, Contract, ethers, utils } from "ethers";
import { TeddyTokenABI, TeddyTokenFactory } from "@luckyfriday/contracts";
import config, { cache, cacheKey, CHAIN_ID } from "../config";
import { useQuery } from "@apollo/client";
import { seedsQuery } from "./subgraph";
import { useEffect } from "react";

interface TeddyToken {
  name: string;
  description: string;
  image: string;
}

export interface ITeddySeed {
  bodyAccessory: number;
  background: number;
  body: number;
  headAccessory: number;
  face: number;
}

export enum TeddyTokenContractFunction {
  delegateVotes = "votesToDelegate",
}

const abi = new utils.Interface(TeddyTokenABI.abi);
const seedCacheKey = cacheKey(
  cache.seed,
  CHAIN_ID,
  config.addresses.teddyToken
);

const isSeedValid = (seed: Record<string, any> | undefined) => {
  const expectedKeys = [
    "background",
    "body",
    "bodyAccessory",
    "headAccessory",
    "face",
  ];
  const hasExpectedKeys = expectedKeys.every((key) =>
    (seed || {}).hasOwnProperty(key)
  );
  const hasValidValues = Object.values(seed || {}).some((v) => v !== 0);
  return hasExpectedKeys && hasValidValues;
};

export const useTeddyToken = (teddyId: EthersBN) => {
  const [teddy] =
    useContractCall<[string]>({
      abi,
      address: config.addresses.teddyToken,
      method: "dataURI",
      args: [teddyId],
    }) || [];

  if (!teddy) {
    return;
  }

  const teddyImgData = teddy.split(";base64,").pop() as string;
  const json: TeddyToken = JSON.parse(atob(teddyImgData));

  return json;
};

const seedArrayToObject = (seeds: (ITeddySeed & { id: string })[]) => {
  return seeds.reduce<Record<string, ITeddySeed>>((acc, seed) => {
    acc[seed.id] = {
      background: Number(seed.background),
      body: Number(seed.body),
      bodyAccessory: Number(seed.bodyAccessory),
      headAccessory: Number(seed.headAccessory),
      face: Number(seed.face),
    };
    return acc;
  }, {});
};

export const useTeddySeeds = () => {
  const cache = localStorage.getItem(seedCacheKey);
  const cachedSeeds = cache ? JSON.parse(cache) : undefined;
  const { data } = useQuery(seedsQuery(), {
    skip: !!cachedSeeds,
  });

  useEffect(() => {
    if (!cachedSeeds && data?.seeds?.length) {
      localStorage.setItem(
        seedCacheKey,
        JSON.stringify(seedArrayToObject(data.seeds))
      );
    }
  }, [data, cachedSeeds]);

  return cachedSeeds;
};

export const useTeddySeed = (teddyId: EthersBN) => {
  const seeds = useTeddySeeds();
  const seed = seeds?.[teddyId.toString()];

  // prettier-ignore
  const request = seed ? false : {
    contract: new Contract(config.addresses.teddyToken, abi),
    method: 'seeds',
    args: [teddyId],
  };
  const seedsCall = useCall(request, {
    chainId: ChainId.Moonbeam
  });
  
  const response = seedsCall?.value

  if (response) {
    const seedCache = localStorage.getItem(seedCacheKey);
    if (seedCache && isSeedValid(response)) {
      const updatedSeedCache = JSON.stringify({
        ...JSON.parse(seedCache),
        [teddyId.toString()]: {
          background: response.background,
          body: response.body,
          headAccessory: response.headAccessory,
          bodyAccessory: response.bodyAccessory,
          face: response.face,
        },
      });
      localStorage.setItem(seedCacheKey, updatedSeedCache);
    }
    return response;
  }
  return seed;
};

export const useUserVotes = (): number | undefined => {
  const { account } = useEthers();
  return useAccountVotes(account ?? ethers.constants.AddressZero);
};

export const useAccountVotes = (account?: string): number | undefined => {
  const [votes] =
    useContractCall<[EthersBN]>({
      abi,
      address: config.addresses.teddyToken,
      method: "getCurrentVotes",
      args: [account],
    }) || [];
  return votes?.toNumber();
};

export const useUserDelegatee = (): string | undefined => {
  const { account } = useEthers();
  const [delegate] =
    useContractCall<[string]>({
      abi,
      address: config.addresses.teddyToken,
      method: "delegates",
      args: [account],
    }) || [];
  return delegate;
};

export const useUserVotesAsOfBlock = (
  block: number | undefined
): number | undefined => {
  const { account } = useEthers();

  // Check for available votes
  const [votes] =
    useContractCall<[EthersBN]>({
      abi,
      address: config.addresses.teddyToken,
      method: "getPriorVotes",
      args: [account, block],
    }) || [];
  return votes?.toNumber();
};

export const useDelegateVotes = () => {
  const teddyToken = new TeddyTokenFactory().attach(
    config.addresses.teddyToken
  );

  //@ts-ignore
  const { send, state } = useContractFunction(teddyToken, "delegate");

  return { send, state };
};

export const useTeddyTokenBalance = (address: string): number | undefined => {
  const [tokenBalance] =
    useContractCall<[EthersBN]>({
      abi,
      address: config.addresses.teddyToken,
      method: "balanceOf",
      args: [address],
    }) || [];
  return tokenBalance?.toNumber();
};

export const useUserTeddyTokenBalance = (): number | undefined => {
  const { account } = useEthers();

  const [tokenBalance] =
    useContractCall<[EthersBN]>({
      abi,
      address: config.addresses.teddyToken,
      method: "balanceOf",
      args: [account],
    }) || [];
  return tokenBalance?.toNumber();
};

import React from 'react';
import { NavBarButtonStyle } from '../../NavBarButton';
import { Trans } from '@lingui/macro';
import { Button } from '@chakra-ui/react';

interface WalletConnectButtonProps {
  className: string;
  onClickHandler: () => void;
  buttonStyle: NavBarButtonStyle;
}

const WalletConnectButton: React.FC<WalletConnectButtonProps> = props => {
  const { onClickHandler } = props;
  return (
      <Button
        size="md"
        onClick={onClickHandler}
        width="128px"
      >
        <Trans>Connect</Trans>
      </Button>
  );
};

export default WalletConnectButton;

import { Container, Col, Row, FloatingLabel, Form } from "react-bootstrap";
import { Button, Heading, Box, chakra, Image } from "@chakra-ui/react";
import classes from "./Playground.module.css";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { ImageData, getTeddyData, getRandomTeddySeed } from "@luckyfriday/assets";
import { buildSVG, PNGCollectionEncoder } from "@luckyfriday/sdk";
import Teddy from "../../components/Teddy";
import TeddyModal from "./TeddyModal";
import { Trans } from "@lingui/macro";
import { AnimatePresence, motion, useAnimationControls } from "framer-motion";

import claw from "../../assets/claw-machine/claw-hyper-long.png";
import clawBox from "../../assets/claw-machine/box-with-pile.png";
import { navbarHeight } from "../../theme/constants";

interface Trait {
  title: string;
  traitNames: string[];
}

interface PendingCustomTrait {
  type: string;
  data: string;
  filename: string;
}
// eslint-disable-next-line
const DEFAULT_TRAIT_TYPE = "body";

const encoder = new PNGCollectionEncoder(ImageData.palette);
// eslint-disable-next-line
const traitKeyToTitle: Record<string, string> = {
  bodies: "body",
  bodyAccessories: "bodyAccessory",
  headAccessories: "headAccessory",
  faces: "face",
};

const parseTraitName = (partName: string): string =>
  capitalizeFirstLetter(partName.split("-").join(" "));

const capitalizeFirstLetter = (s: string): string =>
  s.charAt(0).toUpperCase() + s.slice(1);

const traitKeyToLocalizedTraitKeyFirstLetterCapitalized = (
  s: string
): ReactNode => {
  const traitMap = new Map([
    ["background", <Trans>Background</Trans>],
    ["body", <Trans>Body</Trans>],
    ["bodyAccessory", <Trans>Body Accessory</Trans>],
    ["headAccessory", <Trans>Head Accessory</Trans>],
    ["face", <Trans>Face</Trans>],
  ]);

  return traitMap.get(s);
};

const MotionBox = chakra(motion.div)

const Playground: React.FC = () => {
  const [teddySvgs, setTeddySvgs] = useState<string[]>();
  const [pullTeddy, setPullTeddy] = useState<boolean>(false);
  const [traits, setTraits] = useState<Trait[]>();
  const [modSeed, setModSeed] = useState<{ [key: string]: number }>();
  const [initLoad, setInitLoad] = useState<boolean>(true);
  const [displayTeddy, setDisplayTeddy] = useState<boolean>(false);
  const [indexOfTeddyToDisplay, setIndexOfTeddyToDisplay] = useState<number>();
  const [selectIndexes, setSelectIndexes] = useState<Record<string, number>>(
    {}
  );
  // eslint-disable-next-line
  const [pendingTrait, setPendingTrait] = useState<PendingCustomTrait>();
  // eslint-disable-next-line
  const [isPendingTraitValid, setPendingTraitValid] = useState<boolean>();

  const customTraitFileRef = useRef<HTMLInputElement>(null);

  const generateTeddySvg = React.useCallback(
    (amount: number = 1) => {
      for (let i = 0; i < amount; i++) {
        const seed = { ...getRandomTeddySeed(), ...modSeed };
        const { parts, background } = getTeddyData(seed);
        const svg = buildSVG(parts, encoder.data.palette, background);
        setTeddySvgs((prev) => {
          return prev ? [svg, ...prev] : [svg];
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pendingTrait, modSeed]
  );

  useEffect(() => {
    const traitTitles = [
      "body",
      "bodyAccessory",
      "headAccessory",
      "face",
    ];
    const traitNames = [
      ...Object.values(ImageData.images).map((i) => {
        return i.map((imageData) => imageData.filename);
      }),
    ];

    setTraits(
      traitTitles.map((value, index) => {
        return {
          title: value,
          traitNames: traitNames[index],
        };
      })
    );

    if (initLoad) {
      generateTeddySvg(8);
      setInitLoad(false);
    }
  }, [generateTeddySvg, initLoad]);

  const traitOptions = (trait: Trait) => {
    return Array.from(Array(trait.traitNames.length + 1)).map((_, index) => {
      const traitName = trait.traitNames[index - 1];
      const parsedTitle = index === 0 ? `Random` : parseTraitName(traitName);
      return (
        <option key={index} value={traitName}>
          {parsedTitle}
        </option>
      );
    });
  };

  const traitButtonHandler = (trait: Trait, traitIndex: number) => {
    console.log({ trait });
    setModSeed((prev) => {
      // -1 traitIndex = random
      if (traitIndex < 0) {
        let state = { ...prev };
        delete state[trait.title];
        return state;
      }
      return {
        ...prev,
        [trait.title]: traitIndex,
      };
    });
  };

  const resetTraitFileUpload = () => {
    if (customTraitFileRef.current) {
      customTraitFileRef.current.value = "";
    }
  };
  // eslint-disable-next-line
  let pendingTraitErrorTimeout: NodeJS.Timeout;

  const controls = useAnimationControls();

  // eslint-disable-next-line
  const setPendingTraitInvalid = () => {
    setPendingTraitValid(false);
    resetTraitFileUpload();
    pendingTraitErrorTimeout = setTimeout(() => {
      setPendingTraitValid(undefined);
    }, 5_000);
  };

  /**

  const validateAndSetCustomTrait = (file: File | undefined) => {
    if (pendingTraitErrorTimeout) {
      clearTimeout(pendingTraitErrorTimeout);
    }
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        //@ts-ignore
        const buffer = Buffer.from(e?.target?.result!);
        const png = PNG.sync.read(buffer);
        if (png.width !== 32 || png.height !== 32) {
          throw new Error("Image must be 32x32");
        }
        const filename = file.name?.replace(".png", "") || "custom";
        const data = encoder.encodeImage(filename, {
          width: png.width,
          height: png.height,
          rgbaAt: (x: number, y: number) => {
            const idx = (png.width * y + x) << 2;
            const [r, g, b, a] = [
              png.data[idx],
              png.data[idx + 1],
              png.data[idx + 2],
              png.data[idx + 3],
            ];
            return {
              r,
              g,
              b,
              a,
            };
          },
        });
        setPendingTrait({
          data,
          filename,
          type: DEFAULT_TRAIT_TYPE,
        });
        setPendingTraitValid(true);
      } catch (error) {
        setPendingTraitInvalid();
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const uploadCustomTrait = () => {
    const { type, data, filename } = pendingTrait || {};
    if (type && data && filename) {
      const images = ImageData.images as Record<string, EncodedImage[]>;

      console.log(images);
      images[type].unshift({
        filename,
        data,
      });
      const title = traitKeyToTitle[type];
      const trait = traits?.find((t) => t.title === title);

      resetTraitFileUpload();
      setPendingTrait(undefined);
      setPendingTraitValid(undefined);
      traitButtonHandler(trait!, 0);
      setSelectIndexes({
        ...selectIndexes,
        [title]: 0,
      });
    }
  };
  */

  useEffect(() => {
    if(pullTeddy) {
      controls.start("hidden")
      setPullTeddy(false)
    } else{
      controls.start("animate")
    }
  }, [pullTeddy, controls])

  const teddyContent = () => (
    <AnimatePresence mode="wait" initial={false}>
      <MotionBox
        initial={["animate"]}
        animate={controls}
        key={teddySvgs && teddySvgs[teddySvgs?.length - 1]}
        variants={{
          animate: {
            y: -350,
            opacity: 1,
            transition: {
              type: "spring",
              damping: 40,
              stiffness: 80,
            },
          },
          hidden: {
            y: 1800,
            opacity: 0,
            transition: {
              type: "spring",
              damping: 10,
              stiffness: 100,
            },
          },
        }}
        exit={{
          y: -350,
          transition: {
            type: "spring",
            damping: 10,
            stiffness: 100,
          },
        }}
        className={classes.teddyWrapper}
        style={{
          transformOrigin: `50% 0`,
          cursor: `pointer`,
          width: `100%`,
        }}
        position={["absolute", "absolute", "absolute"]}
        transform={"translateY(300px)"}
      >
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Image
            src={claw}
            alt="claw"
            position="absolute"
            top={0}
            transform={"translateY(calc(-100% + 80px))"}
            maxWidth="64px"
            margin="0 auto"
          />
          <Box maxW="256px" margin="0 auto" width="100%">
            <Box
              onClick={() => {
                setIndexOfTeddyToDisplay(0);
                setDisplayTeddy(true);
              }}
            >
              {teddySvgs && teddySvgs.length > 0 && (
                <Teddy
                  imgPath={`data:image/svg+xml;base64,${btoa(teddySvgs[0])}`}
                  alt="teddy"
                  className={classes.teddyImg}
                  wrapperClassName={classes.teddyWrapper}
                />
              )}
            </Box>
          </Box>
        </Box>
      </MotionBox>
    </AnimatePresence>
  );

  return (
    <Box pt={[4, 4, 4, navbarHeight]}>
      {displayTeddy && indexOfTeddyToDisplay !== undefined && teddySvgs && (
        <TeddyModal
          onDismiss={() => {
            setDisplayTeddy(false);
          }}
          svg={teddySvgs[indexOfTeddyToDisplay]}
        />
      )}

      <Container fluid="lg" style={{ paddingBottom: `64px` }}>
        <Row>
          <Col lg={10} className={classes.headerRow}>
            <Heading as="h1">
              <Trans>Toy Box</Trans>
            </Heading>
            <Box p={0} maxWidth="md">
              <p>
                Play around with different Teddy traits to see what possible
                combinations could be created!
              </p>
            </Box>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Col lg={12}>
              <Button
                onClick={() => {
                  setPullTeddy(true);
                  generateTeddySvg();
                }}
                colorScheme="brand"
                size="lg"
                width="100%"
                zIndex={2}
              >
                <Trans>Generate a Teddy</Trans>
              </Button>
            </Col>
            <Row>
              {traits &&
                traits.map((trait, index) => {
                  return (
                    <Col lg={12} xs={6} key={index}>
                      <Form className={classes.traitForm}>
                        <FloatingLabel
                          controlId="floatingSelect"
                          label={traitKeyToLocalizedTraitKeyFirstLetterCapitalized(
                            trait.title
                          )}
                          key={index}
                          className={classes.floatingLabel}
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            className={classes.traitFormBtn}
                            value={
                              trait.traitNames[selectIndexes?.[trait.title]] ??
                              -1
                            }
                            onChange={(e) => {
                              let index = e.currentTarget.selectedIndex;
                              traitButtonHandler(trait, index - 1); // - 1 to account for 'random'
                              setSelectIndexes({
                                ...selectIndexes,
                                [trait.title]: index - 1,
                              });
                            }}
                          >
                            {traitOptions(trait)}
                          </Form.Select>
                        </FloatingLabel>
                      </Form>
                    </Col>
                  );
                })}
            </Row>
            {/**
             <label
              style={{ margin: "1rem 0 .25rem 0" }}
              htmlFor="custom-trait-upload"
            >
              <Trans>Upload Custom Trait</Trans>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="top"
                overlay={
                  <Popover>
                    <div style={{ padding: "0.25rem" }}>
                      <Trans>Only 32x32 PNG images are accepted</Trans>
                    </div>
                  </Popover>
                }
              >
                <Image
                  style={{ margin: "0 0 .25rem .25rem" }}
                  src={InfoIcon}
                  className={classes.voteIcon}
                />
              </OverlayTrigger>
            </label>
            <Form.Control
              type="file"
              id="custom-trait-upload"
              accept="image/PNG"
              isValid={isPendingTraitValid}
              isInvalid={isPendingTraitValid === false}
              ref={customTraitFileRef}
              className={classes.fileUpload}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                validateAndSetCustomTrait(e.target.files?.[0])
              }
            />
            {pendingTrait && (
              <>
                <FloatingLabel
                  label="Custom Trait Type"
                  className={classes.floatingLabel}
                >
                  <Form.Select
                    aria-label="Custom Trait Type"
                    className={classes.traitFormBtn}
                    onChange={(e) =>
                      setPendingTrait({ ...pendingTrait, type: e.target.value })
                    }
                  >
                    {Object.entries(traitKeyToTitle).map(([key, title]) => (
                      <option value={key}>
                        {capitalizeFirstLetter(title)}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
                <Button
                  onClick={() => uploadCustomTrait()}
                  className={classes.primaryBtn}
                >
                  <Trans>Upload</Trans>
                </Button>
              </>
            )}
             */}
          </Col>
          <Col lg={9}>
            <Box
              overflow={["hidden", "hidden", "hidden", "unset"]}
              paddingTop={["120px", "120px", "120px", 0]}
            >
              <Box
                display="flex"
                justifyContent="center"
                position="relative"
                mt={[80, 80, 80, 40]}
              >
                {teddyContent()}
              </Box>
              <Box mt={-20}>
                <Image
                  src={clawBox}
                  alt="teddy pile"
                  width="100%"
                  maxWidth="600px"
                  margin="0 auto"
                  zIndex={1}
                  position="relative"
                  // marginLeft={[0, 0, -24]}
                />
              </Box>
            </Box>
            <Box bg="white" position="relative">
              <Row
                style={{
                  borderTop: `8px solid`,
                  borderLeft: `8px solid`,
                }}
              >
                {teddySvgs &&
                  teddySvgs.map((svg, i) => {
                    return (
                      <Col
                        xs={4}
                        lg={3}
                        key={i}
                        style={{
                          borderRight: `8px solid`,
                          borderBottom: `8px solid`,
                          borderColor: `var(--brand-dark-background)`,
                          borderStyle: `solid`,
                          // backgroundColor: `var(--brand-cool-background)`,
                        }}
                      >
                        <div
                          onClick={() => {
                            setIndexOfTeddyToDisplay(i);
                            setDisplayTeddy(true);
                          }}
                        >
                          <Box>
                            <Teddy
                              imgPath={`data:image/svg+xml;base64,${btoa(svg)}`}
                              alt="teddy"
                              className={classes.teddyImg}
                              wrapperClassName={classes.teddyWrapper}
                            />
                          </Box>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </Box>
          </Col>
        </Row>
      </Container>
    </Box>
  );
};
export default Playground;

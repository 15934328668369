import classes from "./Teddy.module.css";
import React from "react";
import loadingTeddy from "../../assets/teddy-illustrations/teddy-loading-small.gif";
import Image from "react-bootstrap/Image";

export const LoadingTeddy = () => {
  return (
    <div className={classes.imgWrapper}>
      <Image
        className={classes.img}
        src={loadingTeddy}
        alt={"loading teddy"}
        fluid
      />
    </div>
  );
};

const Teddy: React.FC<{
  imgPath: string;
  alt: string;
  className?: string;
  wrapperClassName?: string;
}> = (props) => {
  const { imgPath, alt, className, wrapperClassName } = props;
  return (
    <div className={`${classes.imgWrapper} ${wrapperClassName}`}>
      <Image
        className={`${classes.img} ${className}`}
        src={imgPath ? imgPath : loadingTeddy}
        alt={alt}
        fluid
      />
    </div>
  );
};

export default Teddy;

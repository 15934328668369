import {
  Box,
  Container,
  Heading,
  HStack,
  Text,
  Input,
  Stack,
  FormLabel,
  IconButton,
  Link,
  Tooltip,
  List,
  ListItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Divider,
} from "@chakra-ui/react";
// @ts-ignore

import { supabase } from "../../wrappers/supabaseClient";
import { ExternalLinkIcon, InfoIcon } from "@chakra-ui/icons";
import { useQuery } from "@tanstack/react-query";

export type BidObject = {
  charityName: string;
  charityId: string;
  donorName: string | undefined;
  value: string;
};

export type CharityObject = {
  charityName: string;
  charityId: string;
  charityDescription: string;
  charityLogo: string;
  charityUrl: string;
};

type BidSelectorModalProps = {
  onPlaceBid: (bid: BidObject) => void;
  onChangeBidValue: (value: string) => void;
  onChangeDonorName: (value: string) => void;
  onSelectCharity?: (charity: CharityObject) => void;
  onClose: () => void;
  isOpen: boolean;
  bidValue: string;
  minBid: number;
  donorName: string | undefined;
};

type SelectorView = "amount" | "charity" | "donorName";

export const BidSelectorModal = (props: BidSelectorModalProps): JSX.Element => {
  const {
    onPlaceBid,
    onSelectCharity,
    onChangeBidValue,
    onChangeDonorName,
    bidValue,
    minBid,
    isOpen,
    onClose,
    donorName,
  } = props;
  const [view, setView] = useState<SelectorView>("charity");
  const [charityId, setCharityId] = useState("");
  const [charityName, setCharityName] = useState("");
  const [charityDescription, setCharityDescription] = useState("");
  const [charityLogo, setCharityLogo] = useState("");
  const [charityUrl, setCharityUrl] = useState("");

  const handleCharitySelect = (charity: CharityObject) => {
    setCharityName(charity.charityName);
    setCharityId(charity.charityId);
    setCharityDescription(charity.charityDescription);
    setCharityLogo(charity.charityLogo);
    setCharityUrl(charity.charityUrl);
    onSelectCharity && onSelectCharity(charity);
  };

  const handleConfirm = () => {
    onPlaceBid &&
      onPlaceBid({
        charityId: charityId,
        charityName: charityName,
        donorName: donorName,
        value: bidValue,
      });
  };

  const renderView = (view: SelectorView) => {
    if (view === "charity") {
      return (
        <CharitySelector
          onCharitySelect={handleCharitySelect}
          bidValue={bidValue}
          onChangeBidValue={onChangeBidValue}
          onChangeDonorName={onChangeDonorName}
          donorName={donorName}
          onClickGoBack={() => setView("amount")}
          onConfirm={handleConfirm}
          minBid={minBid}
          charity={{
            charityName,
            charityId,
            charityDescription,
            charityLogo,
            charityUrl,
          }}
        />
      );
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="blackAlpha.800" />
        <ModalContent minWidth={[null, null, "3xl"]} mx={4}>
          {renderView(view)}
        </ModalContent>
      </Modal>
    </>
  );
};

type CharitySelectorProps = {
  onCharitySelect: (charity: CharityObject) => void;
  onClickGoBack?: () => void;
  onConfirm: () => void;
  onChangeBidValue: (value: string) => void;
  onChangeDonorName: (value: string) => void;
  bidValue: string;
  minBid: number;
  charity: CharityObject;
  donorName: string | undefined;
};

const CharitySelector = ({
  onCharitySelect,
  onChangeBidValue,
  onChangeDonorName,
  bidValue,
  onConfirm,
  charity,
  donorName,
  minBid,
}: CharitySelectorProps): JSX.Element => {
  const handleCharitySelect = (charity: CharityObject) => {
    onCharitySelect(charity);
  };

  const fetchCharities = async (query: string) => {
    console.log({ query });
    //@ts-ignore
    let { data } = await supabase
      .from("AllCharities")
      .select("Fundraisername,CauseId,MissionStatement,JGURL,LogoDownloadUrl")
      .textSearch("Fundraisername", `'${query}'`);

    return data;
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [isChanging, setIsChanging] = useState(false);

  const charitiesQuery = useQuery({
    queryKey: ["charities", searchTerm],
    queryFn: () => fetchCharities(searchTerm),
  });

  const [isLoaded, setIsLoaded] = useState(false);

  const fetchRandomCharity = async () => {
    console.log("fetching random charity");
    //@ts-ignore
    //@ts-ignore
    let { data } = await supabase
      .from("random_top_charities")
      .select("Fundraisername,CauseId,MissionStatement,JGURL,LogoDownloadUrl")
      .limit(1)
      .single();

    //@ts-ignore
    let charityName = data?.["Fundraisername"];
    //@ts-ignore
    let charityId = data?.["CauseId"];
    //@ts-ignore
    let charityDescription = data?.["MissionStatement"];
    //@ts-ignore
    // turnstoneRef.current?.query(charityName);
    let charityLogo = data?.["LogoDownloadUrl"];

    let charityUrl = data?.["JGURL"];

    onCharitySelect({
      charityName,
      charityId,
      charityDescription,
      charityLogo,
      charityUrl,
    });

    if (isLoaded) {
      setSearchTerm(charityName);
    }
    return data;
  };

  useEffect(() => {
    if (!isLoaded) {
      const fetchCharity = async () => {
        await fetchRandomCharity();
      };
      fetchCharity();

      setIsLoaded(true);
    }

    // eslint-disable-next-line
  }, [isLoaded]);

  const showCombobox = isChanging;

  return (
    <>
      <Flex>
        <Box width="100%">
          <Container margin={0} p={0}>
            <ModalHeader flex={1}>
              <Heading as="h5" fontFamily="heading" fontSize="2xl">
                Place Your Bid
              </Heading>
            </ModalHeader>
          </Container>
        </Box>
        <ModalCloseButton />
      </Flex>
      <Divider borderColor="gray.500" />
      <ModalBody px={8} py={4}>
        <Flex flexDirection={["column", "column", "row"]}>
          <Box minWidth={300} width="100%">
            <Box position="relative" borderRadius={4} zIndex={1}>
              <Box>
                <Input
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setIsChanging(true);
                  }}
                  placeholder="Type to search for a charity"
                  borderRadius={4}
                  tabIndex={-1}
                  autoFocus
                />
              </Box>
              {showCombobox && (
                <Box position="absolute" width="100%" mt={2}>
                  <List
                    as="ul"
                    position="relative"
                    overflowY="scroll"
                    height="250px"
                    width="100%"
                    borderBottomLeftRadius={4}
                    borderBottomRadius={4}
                  >
                    {charitiesQuery.data &&
                      charitiesQuery.data.length > 0 &&
                      charitiesQuery.data.map((charity) => {
                        return (
                          <ListItem
                            key={charity.CauseId}
                            borderRadius={0}
                            width="100%"
                            justifyContent={"flex-start"}
                            alignItems="center"
                            fontSize={"md"}
                          >
                            <Button
                              onClick={() => {
                                handleCharitySelect({
                                  charityId: charity["CauseId"],
                                  charityName: charity["Fundraisername"],
                                  charityDescription:
                                    charity["MissionStatement"],
                                  charityLogo: charity["LogoDownloadUrl"],
                                  charityUrl: charity["JGURL"],
                                });

                                setSearchTerm(charity.Fundraisername);
                                setIsChanging(false);
                              }}
                              width="100%"
                              justifyContent="flex-start"
                              alignItems={"center"}
                              alignContent={"left"}
                              borderRadius={0}
                              py={2}
                              height="auto"
                            >
                              <Box
                                mr={2}
                                alignContent={"left"}
                                textAlign="left"
                                justifyContent="flex-start"
                                flexDirection="row"
                              >
                                <Text as="span" fontSize="md">
                                  {charity.Fundraisername}
                                </Text>
                              </Box>
                            </Button>
                          </ListItem>
                        );
                      })}
                  </List>
                </Box>
              )}
            </Box>
          </Box>
          <Box width={["100%", "100%", "auto"]} pl={[0, 0, 2]} pt={[2, 2, 0]}>
            <Button
              colorScheme="teal"
              onClick={fetchRandomCharity}
              width={[`100%`, `100%`, 300]}
            >
              Select random charity
              <Tooltip
                bg="brand.500"
                label="Selects a random charity from the top 400 charities from JustGiving's database."
              >
                <InfoIcon ml={2} color="white" />
              </Tooltip>
            </Button>
          </Box>
        </Flex>
        <Box height={["300px", "300px", 200]} py={8} overflow="scroll">
          {charity.charityId !== "" ? (
            <Box>
              <HStack>
                <Text fontWeight="bold" fontSize="2xl">
                  {charity.charityName}
                </Text>
                <IconButton
                  as={Link}
                  icon={<ExternalLinkIcon />}
                  aria-label="website"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href={charity.charityUrl}
                  variant="ghost"
                />
              </HStack>
              <Text mt={2}>{charity.charityDescription}</Text>
            </Box>
          ) : (
            <Text>No charity selected</Text>
          )}
        </Box>

        <Box py={2} pb={8}>
          <Stack
            flexDirection={["column", "column", "row"]}
            alignItems={["flex-end", "flex-end"]}
            justifyContent="space-between"
          >
            <HStack spacing={4}>
              <Stack spacing={0}>
                <FormLabel fontWeight="bold">Donor Name</FormLabel>
                <Input
                  type="text"
                  isRequired
                  value={donorName}
                  onChange={(e) => onChangeDonorName(e.target.value)}
                />
              </Stack>
              <Stack spacing={0}>
                <FormLabel fontWeight="bold">Bid</FormLabel>
                <Input
                  type="number"
                  value={bidValue.toString()}
                  placeholder={`${minBid} GLMR or more`}
                  onChange={(e) => {
                    onChangeBidValue(e.target.value);
                  }}
                />
              </Stack>
            </HStack>
            <Button onClick={onConfirm} minW="150px" colorScheme="brand">
              Place Bid
            </Button>
          </Stack>
        </Box>
      </ModalBody>
    </>
  );
};

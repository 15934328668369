import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
  Divider,
  Stack,
  Flex,
  HStack,
  Image,
  ModalOverlay,
} from "@chakra-ui/react";

import { Moonbeam, useEthers, ChainId } from "@usedapp/core";
import { CopyIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { TalismanConnector } from "@talismn/web3react-v6-connector";
import { CHAIN_ID, WALLET_CONNECT_PROJECT_ID } from "../../config";
import { usePreferredConnector } from "../../hooks/useAutoConnect";

import talismanSvg from "../../assets/wallet-brand-assets/talisman.svg";
import metamask from "../../assets/wallet-brand-assets/metamask-fox.svg";
import walletConnectSvg from '../../assets/wallet-brand-assets/walletconnect-logo.svg'

type ConnectToWalletModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConnectToWallet?: () => void;
};
export const WalletConnectModal = ({
  onClose,
  isOpen,
  onConnectToWallet,
}: ConnectToWalletModalProps) => {
  const { account, deactivate } = useEthers();
  // const [web3modal, setWeb3modal] = useState(null)

  const disconnect = () => {
    deactivate();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent py={8}>
          {account ? (
            <LoggedInModalContent account={account} onLogout={disconnect} />
          ) : (
            <ConnectToWalletModalContent
              onConnectToWallet={() => {
                onConnectToWallet && onConnectToWallet();
                onClose();
              }}
            />
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

type ConnectToWalletModalContentProps = {
  onConnectToWallet: () => void;
};

export const ConnectToWalletModalContent = ({
  onConnectToWallet,
}: ConnectToWalletModalContentProps) => {
  const { activateBrowserWallet, activate } = useEthers();
  const { setPreferredConnector, clearPreferredConnector } =
    usePreferredConnector();

  const browserWalletConnect = () => {
    try {
      activateBrowserWallet();
      setPreferredConnector("metamask");
      onConnectToWallet && onConnectToWallet();
    } catch (error) {
      console.log(error);
    }
  };

  const walletConnect = async () => {
    try {
      const provider = await EthereumProvider.init({
        projectId: WALLET_CONNECT_PROJECT_ID, // required
        chains: [ChainId.Moonbeam], // required
        showQrModal: true, // requires @walletconnect/modal
      });

      await provider.enable();
      await activate(provider);
      clearPreferredConnector();
      onConnectToWallet && onConnectToWallet();
    } catch (error) {
      console.log(error);
    }
  };

  const talismanConnect = async () => {
    try {
      const talisman = new TalismanConnector({
        supportedChainIds: [1284, 1285, 1287],
      });
      await activate(talisman);
      setPreferredConnector("talisman");
    } catch (error) {
      window.open("https://www.talisman.xyz/", "_blank");
    }
  };

  return (
    <>
      <Flex>
        <ModalHeader
          flex={1}
          fontFamily="heading"
          fontSize="2xl"
          textAlign="center"
        >
          Connect to a wallet
        </ModalHeader>
        <ModalCloseButton />
      </Flex>
      <ModalBody py={5}>
        <Stack>
          <Button
            onClick={browserWalletConnect}
            sx={{
              display: `flex`,
              justifyContent: `space-between`,
              alignItems: `center`,
            }}
            variant="light"
            border="2px solid"
            borderColor="gray.600"
            height="48px"
          >
            Metamask
            <Image src={metamask} alt="metamask" width="32px" height="32px" />
          </Button>
          <Button
            onClick={talismanConnect}
            sx={{
              display: `flex`,
              justifyContent: `space-between`,
              alignItems: `center`,
            }}
            variant="light"
            border="2px solid"
            borderColor="gray.600"
            height="48px"
            rightIcon={
              <Image
                src={talismanSvg}
                alt="metamask"
                width="64px"
                height="64px"
              />
            }
          >
            <Text as="span">Talisman</Text>
          </Button>
          <Button
            onClick={walletConnect}
            sx={{
              display: `flex`,
              justifyContent: `space-between`,
              alignItems: `center`,
            }}
            variant="light"
            border="2px solid"
            borderColor="gray.600"
            height="48px"
            rightIcon={
              <Image
                src={walletConnectSvg}
                alt="metamask"
                width="32px"
                height="32px"
              />
            }
          >
            <Text as="span">Wallet Connect</Text>
          </Button>
        </Stack>
      </ModalBody>
    </>
  );
};

type LoggedInModalContentProps = {
  account: string;
  onLogout: () => void;
};

const LoggedInModalContent = ({
  account,
  onLogout,
}: LoggedInModalContentProps) => {
  const [copied, setCopied] = useState(false);

  return (
    <>
      <Flex>
        <ModalHeader flex={1} fontFamily="heading">
          Your wallet
        </ModalHeader>
        <ModalCloseButton />
      </Flex>
      <Divider borderColor="gray.500" />
      <ModalBody py={8}>
        <Flex
          sx={{
            flexDirection: `column`,
          }}
        >
          <Stack mb={10}>
            <Text
              fontSize="xl"
              fontFamily="heading"
              fontWeight="bold"
              sx={{
                whiteSpace: `nowrap`,
                overflow: `hidden`,
                textOverflow: `ellipsis`,
              }}
            >
              {account}
            </Text>
            <HStack spacing={4} sx={{ position: `relative` }}>
              <Flex alignItems="baseline">
                <Text fontWeight="bold" fontSize="sm" mr={1}>
                  View on {CHAIN_ID}
                </Text>
                <a
                  href={Moonbeam.getExplorerAddressLink(account)}
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  <ExternalLinkIcon />
                </a>
              </Flex>
              <Box>
                <Flex alignItems="baseline">
                  <Text fontWeight="bold" fontSize="sm" mr={1}>
                    Copy address
                  </Text>
                  <CopyToClipboard
                    text={account}
                    onCopy={() => {
                      setCopied(true);
                      console.log({ copied });
                    }}
                  >
                    <button>
                      <CopyIcon />
                    </button>
                  </CopyToClipboard>
                </Flex>
              </Box>
            </HStack>
          </Stack>
          <Button onClick={onLogout} variant="danger">
            Disconnect
          </Button>
        </Flex>
      </ModalBody>
    </>
  );
};

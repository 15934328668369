import exrLogo from "../../assets/sponsors/exr_logo_white_stacked.png";
import artsDaoLogo from "../../assets/sponsors/arts-dao-white.svg";

export const customTeddyConfig = [
  {
    id: 157,
    sponsorName: "Human Protocol",
    message: "This custom teddy is brought to you by Human Protocol.",
    link: `https://humanprotocol.org/`,
  },
  {
    id: 165,
    sponsorName: "Bulldog1205",
    message: "Custom made by Bulldog1205.",
    link: `https://teddydao.org/`,
  },
  {
    id: 177,
    sponsorName: "Data Dash",
    message: "Custom made by Bulldog1205.",
    link: `https://teddydao.org/`,
  },
  {
    id: 181,
    sponsorName: "Lisa N Edwards",
    message: "Custom made by Bulldog1205.",
    link: `https://teddydao.org/`,
  },
  {
    id: 229,
    sponsorName: "Hover Market",
    message: "Custom made by Bulldog1205.",
    link: `https://teddydao.org/`,
  },
  {
    id: 230,
    sponsorName: "Hover Market",
    message: "Custom made by Bulldog1205.",
    link: `https://teddydao.org/`,
  },
  {
    id: 238,
    sponsorName: "Exiled Racers",
    message: "Custom made by Bulldog1205.",
    link: `https://www.exiledracers.com/`,
    logo: exrLogo,
  },
  {
    id: 301,
    sponsorName: "Arts DAO",
    message: "Custom made by Bulldog1205.",
    link: `https://artsdaofest.com/`,
    logo: artsDaoLogo,
  },
];

export function findCustomTeddyById(idToFind: number) {
  return customTeddyConfig.find((item) => item.id === idToFind) || null;
}

import { BigNumber } from "@ethersproject/bignumber";
import { useAppSelector } from "../hooks";
import { Bid, BidEvent } from "../utils/types";
import { Auction } from "./teddyAuction";

const deserializeAuction = (reduxSafeAuction: Auction): Auction => {
  return {
    amount: BigNumber.from(reduxSafeAuction.amount),
    bidder: reduxSafeAuction.bidder,
    startTime: BigNumber.from(reduxSafeAuction.startTime),
    endTime: BigNumber.from(reduxSafeAuction.endTime),
    teddyId: BigNumber.from(reduxSafeAuction.teddyId),
    settled: false,
  };
};

const deserializeBid = (reduxSafeBid: BidEvent): Bid => {
  return {
    teddyId: BigNumber.from(reduxSafeBid.teddyId),
    sender: reduxSafeBid.sender,
    value: BigNumber.from(reduxSafeBid.value),
    extended: reduxSafeBid.extended,
    transactionHash: reduxSafeBid.transactionHash,
    timestamp: BigNumber.from(reduxSafeBid.timestamp),
    charityName: reduxSafeBid.charityName,
    donorName: reduxSafeBid.donorName,
  };
};
const deserializeBids = (reduxSafeBids: BidEvent[]): Bid[] => {
  return reduxSafeBids
    .map((bid) => deserializeBid(bid))
    .sort((a: Bid, b: Bid) => {
      return b.timestamp.toNumber() - a.timestamp.toNumber();
    });
};

const useOnDisplayAuction = (): Auction | undefined => {
  const lastAuctionTeddyId = useAppSelector(
    (state) => state.auction.activeAuction?.teddyId
  );
  const onDisplayAuctionTeddyId = useAppSelector(
    (state) => state.onDisplayAuction.onDisplayAuctionTeddyId
  );

  const currentAuction = useAppSelector((state) => state.auction.activeAuction);
  const pastAuctions = useAppSelector(
    (state) => state.pastAuctions.pastAuctions
  );

  console.log({
    onDisplayAuctionTeddyId,
    lastAuctionTeddyId,
    currentAuction,
    pastAuctions,
  });

  if (
    onDisplayAuctionTeddyId === undefined ||
    lastAuctionTeddyId === undefined ||
    currentAuction === undefined ||
    !pastAuctions
  )
    return undefined;

  // current auction
  if (BigNumber.from(onDisplayAuctionTeddyId).eq(lastAuctionTeddyId)) {
    return deserializeAuction(currentAuction);
  }

  // past auction
  const reduxSafeAuction: Auction | undefined = pastAuctions.find((auction) => {
    const teddyId =
      auction.activeAuction && BigNumber.from(auction.activeAuction.teddyId);
    return teddyId && teddyId.toNumber() === onDisplayAuctionTeddyId;
  })?.activeAuction;

  return reduxSafeAuction ? deserializeAuction(reduxSafeAuction) : undefined;
};

export const useAuctionBids = (auctionTeddyId: BigNumber): Bid[] | undefined => {
  const lastAuctionTeddyId = useAppSelector(
    (state) => state.onDisplayAuction.lastAuctionTeddyId
  );
  const lastAuctionBids = useAppSelector((state) => state.auction.bids);
  const pastAuctions = useAppSelector(
    (state) => state.pastAuctions.pastAuctions
  );

  // auction requested is active auction
  /** 
  if (lastAuctionTeddyId === auctionTeddyId.toNumber()) {
    return deserializeBids(lastAuctionBids);
  } else {
  }
  */

  // find bids for past auction requested
  const bidEvents: BidEvent[] | undefined = pastAuctions.find((auction) => {
    const teddyId =
      auction.activeAuction && BigNumber.from(auction.activeAuction.teddyId);
    return teddyId && teddyId.eq(auctionTeddyId);
  })?.bids;

  return bidEvents && deserializeBids(bidEvents);
};

export default useOnDisplayAuction;

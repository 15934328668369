import { Col } from "react-bootstrap";
import {
  Badge,
  Button,
  Image,
  useDisclosure,
  Link as ChakraLink,
} from "@chakra-ui/react";
import Section from "../../layout/Section";
import classes from "./Landing.module.css";
import _teddyDaoGroup from "../../assets/teddy_dao_group.png";

import moonbeam from "../../assets/logos/moonbeam-logo-new.png";
import justgiving from "../../assets/logos/just-giving-logo.png";
import luckyFriday from "../../assets/logos/lf-nft-horizontal.png";
import teddyPaper from "../../assets/background/teddy-paper.png";

import bearIcon from "../../assets/teddy-icons/bear-icon.png";
import dao from "../../assets/teddy-icons/teddy-dao-icon.png";
import loveIcon from "../../assets/teddy-icons/love-icon.png";
import presentIcon from "../../assets/teddy-icons/present-icon.png";
import swagIcon from "../../assets/teddy-icons/swag-icon.png";
import trophyIcon from "../../assets/teddy-icons/trophy-icon.png";

import teddyHeart from "../../assets/teddy-illustrations/teddy3.png";
import teddyMascot from "../../assets/teddy-illustrations/teddy-mascot.png";
import teddyQuestion from "../../assets/teddy-illustrations/teddy2.png";
import teddyMallet from "../../assets/teddy-illustrations/teddy1.png";
import teddyWallet from "../../assets/teddy-icons/teddy-wallet.png";
import { UpperBgWave } from "../../components/Background/UpperBgWave";
import { LowerBgWave } from "../../components/Background/LowerBgWave";

import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  VStack,
  Container,
} from "@chakra-ui/react";
import { navbarHeight } from "../../theme/constants";

import { WalletConnectModal } from "../../components/WalletConnectModal";
import { useQuery } from "@apollo/client";
import { charitiesQuery } from "../../wrappers/subgraph";
import { formatEther, parseEther } from "ethers/lib/utils";
import { FeaturedOnSection } from "./Sections/FeaturedOnSection";

type Donation = {
  id: string;
  totalDonationsReceived: bigint;
  name: string;
};

function sumTotalDonations(donations: Donation[]): bigint {
  return donations.reduce((acc, donation) => {
    return acc + BigInt(donation.totalDonationsReceived);
  }, BigInt(0));
}

function kFormatter(num: any) {
  return Math.abs(num) > 999
    ? // @ts-ignore
      Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
}

const LandingPage = () => {
  const walletDisclosure = useDisclosure();

  const _charitiesQuery = useQuery(charitiesQuery());
  console.log({ _charitiesQuery });

  const totalDonations =
    _charitiesQuery?.data?.charities &&
    sumTotalDonations(_charitiesQuery?.data?.charities);

  console.log({ totalDonations });

  const totalGlmrDonated =
    (totalDonations && kFormatter(Number(formatEther(totalDonations)))) ||
    `N/A`;

  return (
    <Box pt={[8, 8, 8, navbarHeight]}>
      <Section fullWidth={true} className={classes.pageSection}>
        <Container maxWidth="4xl">
          <div>
            <Col lg={7} style={{ marginLeft: `auto`, marginRight: `auto` }}>
              <p className={classes.subheading}>Bears with an impact</p>
              <h1
                className={`${classes.heading}`}
                style={{ fontWeight: `extrabold` }}
              >
                <Trans>Donations designed for web3</Trans>
              </h1>
              <Text textAlign="center" color="accent.500" fontWeight="bold">
                Live now
              </Text>
            </Col>
            <Container maxWidth="4xl">
              <Image src={_teddyDaoGroup} alt="teddy dao" />
              <div
                className={classes.buttonGroup}
                style={{ paddingTop: `32px` }}
              >
                <Link
                  to="/auction"
                  className={`${classes.linkButtonDark} ${classes.linkButton}`}
                  style={{ minWidth: `150px` }}
                >
                  Adopt-a-Teddy
                </Link>
                <a
                  href="#how-it-works"
                  className={`${classes.linkButtonLight} ${classes.linkButton}`}
                  style={{ minWidth: `150px` }}
                >
                  Learn more
                </a>
              </div>
            </Container>
          </div>
        </Container>
      </Section>
      <Box position="relative">
        <Box pt={12} pb={24}>
          <VStack width="100%" borderRadius={"6px"} ml={2}>
            <Text color="brand.500" opacity={0.7} fontStyle="italic" mb={2}>
              Lovingly curated by:
            </Text>
            <Flex
              flexDirection={["column", "column", "row", "row"]}
              px={8}
              pt={2}
              alignItems={["center", "center", "flex-start"]}
              justifyContent={"center"}
              gap={[10]}
            >
              <Image src={moonbeam} alt="moonbeam" maxHeight="43px" />
              <Image src={luckyFriday} alt="lucky Friday" maxHeight="77px" />
              <Image src={justgiving} alt="just giving" maxHeight="77px" />
            </Flex>
          </VStack>
        </Box>
        <Box position="absolute" bottom={0} width="100%">
          <UpperBgWave
            viewBox="0 0 1437.5 42.5"
            width="100%"
            preserveAspectRatio="none"
          />
        </Box>
      </Box>

      <Box
        sx={{
          background: `url(${teddyPaper})`,
          backgroundRepeat: `repeat`,
        }}
        color="white"
        py={24}
        px={[4, 4, 4, 0]}
        position="relative"
      >
        <Container maxWidth="2xl">
          <p className={classes.sectionWhatWeDoSubheading}>What we do</p>
          <h2 className={classes.sectionWhatWeDoTitle}>
            Teddy DAO is a social good NFT project, making a difference where
            YOU think it matters!
          </h2>
          <Flex gap={8}>
            <Box>
              <div style={{ position: `relative` }}>
                <p style={{ padding: `24px 0` }}>
                  Join a daily Teddy auction, and send your winning bid to your
                  favourite charity. We harness NFTs for social good, so adopt a
                  Teddy today and be part of the change!
                </p>
                {/** 
                 *   <Image
                  className={classes.genericTeddy}
                  src={_genericTeddy}
                  width={300}
                  height={300}
                  alt="teddy dao"
                />
                 */}
              </div>
              <Link
                to="/auction"
                className={`${classes.linkButtonLight} ${classes.linkButton}`}
                style={{
                  width: `150px`,
                  background: `white`,
                  fontWeight: `bold`,
                }}
              >
                Adopt-a-Teddy
              </Link>
            </Box>
          </Flex>
          <Box
            position="relative"
            width="100%"
            display="flex"
            justifyContent="flex-end"
          >
            <Image
              src={teddyMascot}
              alt="teddy mascot"
              zIndex={1}
              position="absolute"
              width={["200px", "200px", "200px", "300px"]}
              margin="0 auto"
              transform={[
                "translateY(0)",
                "translateY(0)",
                "translateY(0)",
                "translateY(-75px)",
              ]}
            />
          </Box>
        </Container>
      </Box>
      <div className="relative pt-24">
        <Box position="absolute" top={-1} width="100%">
          <LowerBgWave
            viewBox="0 0 1437.5 42.5"
            width="100%"
            preserveAspectRatio="none"
          />
        </Box>
      </div>
      <Section fullWidth={false} className={classes.teddyNumbers}>
        <h2
          style={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: `32px`,
            marginBottom: `32px`,
          }}
        >
          Teddy DAO in numbers
        </h2>
        <div className="grid md:grid-cols-3 gap-4 --chakra-colors-accent-500">
          <div className="bg-[#e7eff3] rounded-md flex items-center justify-center py-8">
            <p style={{ display: `flex`, alignItems: `center` }}>
              <span
                style={{
                  fontWeight: `bold`,
                  fontSize: `60px`,
                  marginRight: `8px`,
                  color: `#ed7614`,
                }}
              >
                100k+
              </span>
              <span className="text-xl leading-none">Charities</span>
            </p>
          </div>
          <div className="bg-[#e7eff3] rounded-md flex items-center justify-center py-8">
            <div className="flex gap-2">
              <div className="text-5xl font-extrabold text-[#ed7614]">
                {totalGlmrDonated}+
              </div>
              <div className="grid">
                <span className="text-xl leading-none">GLMR</span>
                <span className="text-sm font-bold leading-none">Donated</span>
              </div>
            </div>
          </div>
          <div className="bg-[#e7eff3] rounded-md flex items-center justify-center py-8">
            <p
              style={{ display: `flex`, alignItems: `center`, maxWidth: `80%` }}
            >
              <span
                style={{
                  fontWeight: `bold`,
                  fontSize: `60px`,
                  marginRight: `8px`,
                  color: `#ed7614`,
                }}
              >
                2k+
              </span>
              <span style={{ fontSize: `28px`, lineHeight: 1 }}>
                Community Members
              </span>
            </p>
          </div>
        </div>
      </Section>
      <FeaturedOnSection />
      <Box id="how-it-works" as="section" position="relative" pt={24} pb={48}>
        <Container maxWidth="6xl">
          <Heading as="h2" textAlign="center" mb={12}>
            How it works
          </Heading>
          <Grid
            gridTemplateColumns={["1fr", "1fr", "1fr 1fr", "1fr 1fr 1fr 1fr"]}
            justifyContent="center"
            alignItems="center"
            gridGap={6}
          >
            <Box
              border="2px solid"
              p={4}
              borderRadius={8}
              bg="brand.100"
              position="relative"
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="100%"
            >
              <Badge bg="brand.500" color="white" fontSize="sm">
                Step 01
              </Badge>
              <Box
                minHeight="150px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  src={teddyWallet}
                  alt="teddy dao"
                  zIndex={1}
                  position="relative"
                  maxHeight="128px"
                />
              </Box>
              <Heading as="h3" fontSize="lg" textAlign="center">
                Connect your cryptocurrency wallet by clicking 'Connect' or
                download one from Talisman{" "}
              </Heading>
              <Box pt={"40px"} />
              <Box
                position="absolute"
                bottom={0}
                left={0}
                width="100%"
                display="flex"
                justifyContent="center"
                transform="translateY(50%)"
              >
                <Button
                  variant="brand"
                  as={ChakraLink}
                  href="https://lucky-friday.gitbook.io/teddy-dao/how-to-guides/getting-a-wallet"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  textDecoration="none"
                  _hover={{
                    color: `white`,
                    textDecoration: `none`,
                  }}
                >
                  Getting a wallet
                </Button>
              </Box>
            </Box>
            <Box
              border="2px solid"
              p={4}
              borderRadius={8}
              bg="brand.100"
              position="relative"
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="100%"
            >
              <Badge bg="brand.500" color="white" fontSize="sm">
                Step 02
              </Badge>
              <Box minHeight="150px">
                <Image
                  src={teddyMallet}
                  alt="teddy dao"
                  zIndex={1}
                  position="relative"
                  maxHeight="128px"
                />
              </Box>
              <Heading as="h3" fontSize="lg" textAlign="center">
                Go to the{" "}
                <Link to="/auction" style={{ textDecoration: `underline` }}>
                  Auction tab
                </Link>{" "}
                and place your bid for today{`'s`} Teddy using $GLMR
              </Heading>
              <Box pt={"40px"} />
              <Box
                position="absolute"
                bottom={0}
                left={0}
                width="100%"
                display="flex"
                justifyContent="center"
                transform="translateY(50%)"
              >
                <Button
                  variant="brand"
                  as={ChakraLink}
                  href="https://lucky-friday.gitbook.io/teddy-dao/how-to-guides/getting-usdglmr-to-bid"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  _hover={{
                    color: `white`,
                    textDecoration: `none`,
                  }}
                >
                  How to get GLMR
                </Button>
              </Box>
            </Box>
            <Box
              border="2px solid"
              p={4}
              borderRadius={8}
              bg="brand.100"
              position="relative"
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="100%"
            >
              <Badge bg="brand.500" color="white" fontSize="sm">
                Step 03
              </Badge>
              <Box minHeight="150px">
                <Image
                  src={teddyQuestion}
                  alt="teddy dao"
                  zIndex={1}
                  position="relative"
                  maxHeight="128px"
                  margin="0 auto"
                />
              </Box>
              <Heading as="h3" fontSize="lg" textAlign="center">
                Select the charity you want to donate to through our partner
                JustGiving.
              </Heading>
              <Box pt={"40px"} />
              <Box
                position="absolute"
                bottom={0}
                left={0}
                width="100%"
                display="flex"
                justifyContent="center"
                transform="translateY(50%)"
              >
                <Button
                  to="/charities"
                  variant="brand"
                  as={Link}
                  _hover={{
                    color: `white`,
                    textDecoration: `none`,
                  }}
                >
                  Know your charity
                </Button>
              </Box>
            </Box>
            <Box
              border="2px solid"
              p={4}
              borderRadius={8}
              bg="brand.100"
              position="relative"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Badge bg="brand.500" color="white" fontSize="sm">
                Step 04
              </Badge>
              <Box minHeight="150px">
                <Image
                  src={teddyHeart}
                  alt="teddy heart"
                  zIndex={1}
                  position="relative"
                  maxHeight="128px"
                  margin="0 auto"
                />
              </Box>
              <Heading as="h3" fontSize="lg" textAlign="center">
                If you{`'re`} the highest bidder at the end of the auction, you
                will receive the Teddy NFT and your donation will be converted
                to $ and sent to your selected charity.
              </Heading>
              <Box pt={"40px"} />
            </Box>
          </Grid>
        </Container>
      </Box>
      <Box as="section" position="relative" pt={0} pb={48}>
        <Container maxWidth="8xl">
          <Box>
            <Heading as="h3" textAlign="center">
              Tutorials
            </Heading>
          </Box>
          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems="center"
            justifyContent="center"
            padding={4}
          >
            <Box flex="1" minWidth={{ md: "50%", lg: "40%" }} padding={4}>
              <iframe
                width="100%"
                height="400" // You can adjust the height as needed
                src="https://www.youtube.com/embed/sihdePgrdxQ"
                title="YouTube Video 1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Box>
            <Box flex="1" minWidth={{ md: "50%", lg: "40%" }} padding={4}>
              <iframe
                width="100%"
                height="400" // You can adjust the height as needed
                src="https://www.youtube.com/embed/RPdl1Sxo1L4"
                title="YouTube Video 2"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Box>
          </Flex>
        </Container>
      </Box>

      <Box
        as="section"
        position="relative"
        bg="brand.500"
        color="white"
        py={24}
        px={4}
      >
        <Heading as="h3" textAlign="center">
          What makes Teddy DAO special
        </Heading>
        <Container maxWidth="6xl">
          <Grid
            gridTemplateColumns={["1fr", "1fr", "1fr 1fr", "1fr 1fr 1fr"]}
            gridGap={20}
            pt={12}
          >
            <Box>
              <Box style={{ position: `relative`, marginBottom: `16px` }}>
                <Image
                  src={bearIcon}
                  alt="teddy dao"
                  zIndex={1}
                  position="relative"
                  maxHeight="64px"
                />
              </Box>
              <Box position="relative">
                <Heading as="h3" fontSize="2xl" mb={2}>
                  One Teddy A Day
                </Heading>
                <Text fontSize="md" maxWidth="md">
                  There is one Teddy minted every day, forever! Each one will be
                  auctioned for 24 hours, if you have placed the highest bid at
                  the end of the 24 hours you will receive the Teddy and the
                  proceeds will be distributed to a Charity of YOUR choice.
                </Text>
              </Box>
            </Box>
            <Box>
              <Box style={{ position: `relative`, marginBottom: `16px` }}>
                <Image
                  src={loveIcon}
                  alt="teddy dao"
                  zIndex={1}
                  position="relative"
                  maxHeight="64px"
                />
              </Box>
              <Box position="relative">
                <Heading as="h3" fontSize="2xl" mb={2}>
                  Environmentally Sustainable
                </Heading>
                <Text fontSize="md" maxWidth="md">
                  Minted on Moonbeam, Teddy DAO runs on the Polkadot ecosystem –
                  the energy-efficient and small carbon footprint proof of stake
                  network. Rest easy knowing that minting and sending funds to
                  your charity has a near-zero environmental impact.
                </Text>
              </Box>
            </Box>
            <Box>
              <Box style={{ position: `relative`, marginBottom: `16px` }}>
                <Image
                  src={dao}
                  alt="teddy dao"
                  zIndex={1}
                  position="relative"
                  maxHeight="64px"
                />
              </Box>
              <Box position="relative">
                <Heading as="h3" fontSize="2xl" mb={2}>
                  Community Membership
                </Heading>
                <Text fontSize="md" maxWidth="md">
                  Owning a Teddy will provide you access to the DAO, with all
                  rights and privileges. Your voice in governance shapes future
                  initiatives as the community strives to foster social good and
                  beyond.
                </Text>
              </Box>
            </Box>
            <Box>
              <Box style={{ position: `relative`, marginBottom: `16px` }}>
                <Image
                  src={presentIcon}
                  alt="teddy dao"
                  zIndex={1}
                  position="relative"
                  maxHeight="64px"
                />
              </Box>
              <Box position="relative">
                <Heading as="h3" fontSize="2xl" mb={2}>
                  Perpetual Donations
                </Heading>
                <Text fontSize="md" maxWidth="md">
                  Once 100 Teddy bears are donated, the first phase of DAO
                  governance begins. Should a secondary market develop, DAO
                  members (Teddy holders) will direct treasury funds, collected
                  from royalties, to charities selected by the members.
                </Text>
              </Box>
            </Box>
            <Box>
              <Box style={{ position: `relative`, marginBottom: `16px` }}>
                <Image
                  src={trophyIcon}
                  alt="teddy dao"
                  zIndex={1}
                  position="relative"
                  maxHeight="64px"
                />
              </Box>
              <Box position="relative">
                <Heading as="h3" fontSize="2xl" mb={2}>
                  Education
                </Heading>
                <Text fontSize="md" maxWidth="md">
                  Part of our mission is to educate and create a bridge between
                  Web3 and charity. Improving donation flow from crypto and
                  educating the world about charitable missions encourages
                  tax-efficient social good.
                </Text>
              </Box>
            </Box>
            <Box>
              <Box style={{ position: `relative`, marginBottom: `16px` }}>
                <Image
                  src={swagIcon}
                  alt="teddy dao"
                  zIndex={1}
                  position="relative"
                  maxHeight="64px"
                />
              </Box>
              <Box position="relative">
                <Heading as="h3" fontSize="2xl" mb={2}>
                  Tax Deductible
                </Heading>
                <Text fontSize="md" maxWidth="md">
                  When you win a Teddy, proceeds of your winning bid are
                  tax-deductible to the extent allowed by law. You get to join
                  the Teddy DAO community and make a difference while receiving
                  a potential tax benefit!
                </Text>
              </Box>
            </Box>
            {/**
             * 
             * <div className={classes.howItWorksCard}>
              <div style={{ position: `relative`, marginBottom: `16px` }}>
                <Image src={_teddy3} width={300} height={300} alt="teddy dao" />
                <Image
                  src={_imageDots}
                  alt="image dots"
                  width="126px"
                  className={classes.cardImageDots}
                />
              </div>
              <div>
                <h3 className={classes.sectionHowItWorksCardTitle}>
                  Tax Deductible
                </h3>
                <p>
                  When you win a Teddy, the proceeds of your winning bid are tax
                  deductible to the extent allowed by law. This means not only
                  do you get to join the Teddy DAO community and make a
                  difference, but it may also have an additional tax benefit.
                </p>
              </div>
            </div>
             */}
          </Grid>
        </Container>
        <Box
          position="absolute"
          left={0}
          top={0}
          transform="translateY(-100%)"
          width="100%"
          sx={{
            svg: {
              path: {
                fill: `brand.500`,
              },
            },
          }}
        >
          <UpperBgWave
            viewBox="0 0 1437.5 42.5"
            width="100%"
            preserveAspectRatio="none"
            fill="none"
          />
        </Box>
      </Box>
      <WalletConnectModal
        isOpen={walletDisclosure.isOpen}
        onClose={walletDisclosure.onClose}
      />
    </Box>
  );
};
export default LandingPage;

import { BigNumber } from "ethers";
import { Heading, HStack, Image, Text } from "@chakra-ui/react";
import classes from "./AuctionActivityTeddyTitle.module.css";
import { convertNumberIntoPixelPng } from "../../utils/convertNumberIntoPixelPng";
import teddyTitle from '../../assets/teddy-title.png'

const AuctionActivityTeddyTitle: React.FC<{
  teddyId: BigNumber | undefined;
  isCool?: boolean;
}> = (props) => {
  const { teddyId } = props;
  const teddyIdAsNum = teddyId ? Number(teddyId?.toString()) : 0;

  const teddyIdArrayOfPngs = convertNumberIntoPixelPng(teddyIdAsNum);


  return (
    <div className={classes.wrapper}>
      <Heading as="h1" py={4}>
        {teddyIdAsNum !== undefined ? (
          <>
            <HStack spacing={5}>
              <Text as="span">
                <Image src={teddyTitle} alt="teddy" height="54px" />
              </Text>
              <HStack>
                {teddyIdArrayOfPngs.map((item) => {
                  if (!item) {
                    return null;
                  }

                  return (
                    <Image src={item.img} alt={item.value} height="54px" />
                  );
                })}
              </HStack>
            </HStack>
          </>
        ) : (
          <Text as="span">Dropping Soon</Text>
        )}
      </Heading>
    </div>
  );
};
export default AuctionActivityTeddyTitle;

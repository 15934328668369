export const LowerBgWave = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1436"
      height="43"
      fill="none"
      {...props}
    >
      <path
        fill="#03193F"
        d="M1437.5 0v31l-79.5 2-97 9.5-52.5-11.5-260 11.5L791 33l-64.5 9.5L420 25l-220.5 8-103-8L0 33V0h1437.5Z"
      />
    </svg>
  );
}
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Link as ChakraLink,
  VStack
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import { NavHashLink } from "react-router-hash-link";
import { ExternalURL, externalURL } from "../../../utils/externalURL";
import { NavGetGLMR } from "../../NavGetGLMR";
import NavWallet from "../../NavWallet";
import { useAppSelector } from "../../../hooks";



type MobileNavProps = {
  isOpen: boolean;
  onClose: () => void;
};
export const MobileNav = ({
  isOpen,
  onClose
} : MobileNavProps) => {
  const activeAccount = useAppSelector((state) => state.account.activeAccount);

  return (
    <>
      <Drawer
        size="full"
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        trapFocus={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton size="lg" top={`calc(32px)`} />
          <DrawerBody pt={32}>
            <Box>
              <VStack
                spacing={4}
                sx={{
                  fontSize: "3xl",
                  fontWeight: `bold`,
                }}
              >
                <NavHashLink to={`/#how-it-works`} onClick={onClose}>
                  How it works
                </NavHashLink>
                <ChakraLink
                  href={externalURL(ExternalURL.gitbook)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Trans>Docs</Trans>
                </ChakraLink>
                <ChakraLink
                  href={externalURL(ExternalURL.discord)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Trans>Community</Trans>
                </ChakraLink>
                <Link to="/leaderboard" onClick={onClose}>
                  <Trans>Leaderboard</Trans>
                </Link>
                <Link to="/toy-box" onClick={onClose}>
                  <Trans>Toy Box</Trans>
                </Link>
                <Link to="/bridge" onClick={onClose}>
                  <Trans>Bridge</Trans>
                </Link>
                <Link to="/auction" onClick={onClose}>
                  <Trans>Auction</Trans>
                </Link>
              </VStack>

              <Box
                sx={{
                  pt: 8,
                  display: `flex`,
                  flexDirection: `column`,
                  alignItems: `center`,
                }}
              >
                <div style={{ marginBottom: `8px` }}>
                  <NavGetGLMR size="md" />
                </div>
                <NavWallet address={activeAccount || "0"} />
              </Box>
            </Box>
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

import { useQuery } from "@apollo/client";
import {
  Heading,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Image,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Container,
  SystemStyleObject,
} from "@chakra-ui/react";
import {
  accountsQueryByTotalDonations,
  charitiesQuery,
  accountQuery,
} from "../../wrappers/subgraph";
import { formatUnits } from "@ethersproject/units";
import { charityNameExtractor } from "../../utils/charityNameExtractor";
import { shortenAddress, useEthers } from "@usedapp/core";
import { navbarHeight } from "../../theme/constants";

import teddyPaper from "../../assets/background/teddy-paper.png";
import trophyIcon from "../../assets/teddy-icons/trophy-icon.png";
import { getAddress } from "ethers/lib/utils";

type Charity = {
  id: string;
  name: string;
  totalDonationsReceived: string;
};

type Account = {
  id: string;
  totalDonations: string;
};

export const CharityLeaderboard = () => {
  const _charitiesQuery = useQuery(charitiesQuery());
  const _accountsQueryByDonations = useQuery(accountsQueryByTotalDonations());
  const { account: userAddress } = useEthers();
  //@ts-ignore
  const _accountQuery = useQuery(
    accountQuery(userAddress ? userAddress.toLowerCase() : "")
  );

  const accountIsRanked: boolean =
    userAddress && _accountsQueryByDonations?.data?.accounts?.length > 0
      ? _accountsQueryByDonations?.data?.accounts.some((account: Account) => {
          console.log(
            getAddress(account.id),
            userAddress && getAddress(userAddress)
          );
          return (
            getAddress(account.id) === userAddress && getAddress(userAddress)
          );
        })
      : false;

  return (
    <Box pt={[4, 4, 4, navbarHeight]} pb={navbarHeight}>
      <Container maxWidth="6xl">
        <Box
          sx={{
            background: `url(${teddyPaper})`,
            backgroundRepeat: `repeat`,
            backgroundSize: `300px 100px`,
          }}
          py={4}
          px={8}
          borderRadius={4}
          mb={8}
        >
          <Heading
            as="h1"
            fontSize={["3xl", "3xl", "3xl", "5xl"]}
            color="white"
            pt={0}
          >
            Leaderboards
          </Heading>
        </Box>
        <Tabs isFitted variant="enclosed">
          <TabList>
            <Tab
              fontSize="xl"
              fontWeight="bold"
              _selected={{ bg: "brand.100" }}
            >
              Top Charities
            </Tab>
            <Tab fontSize="xl" fontWeight="bold" _selected={{ bg: "#e6e8f3" }}>
              Top Donors
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel bg="brand.100" px={0}>
              <TableContainer py={12}>
                <Table variant="simple">
                  <TableCaption>As of {new Date().toDateString()}</TableCaption>
                  <Thead>
                    <Tr>
                      <Th
                        display="flex"
                        alignItems="baseline"
                        justifyContent="center"
                      >
                        <Image
                          src={trophyIcon}
                          alt="teddy dao"
                          zIndex={1}
                          position="relative"
                          maxHeight="24px"
                        />
                      </Th>
                      <Th>Charity</Th>
                      <Th isNumeric>Total Donations Received(GLMR)</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {_charitiesQuery?.data?.charities?.length > 0 &&
                      _charitiesQuery.data.charities.map(
                        (charity: Charity, key: number) => {
                          const { id, name } = charityNameExtractor(
                            charity.name
                          );
                          return (
                            <Tr
                              key={key}
                              sx={{
                                td: {
                                  border: `none`,
                                },
                              }}
                            >
                              <Td
                                isNumeric
                                display="flex"
                                alignItems="baseline"
                                justifyContent="center"
                              >
                                <Text as="span" fontSize="lg">
                                  {key + 1}
                                </Text>
                              </Td>
                              <Td fontWeight="bold">{name || id}</Td>
                              <Td isNumeric fontWeight="bold">
                                {formatUnits(charity.totalDonationsReceived)}
                              </Td>
                            </Tr>
                          );
                        }
                      )}
                  </Tbody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel bg="#e6e8f3" px={0}>
              <TableContainer py={12}>
                <Table variant="simple">
                  <TableCaption>As of {new Date().toDateString()}</TableCaption>
                  <Thead>
                    <Tr>
                      <Th
                        display="flex"
                        alignItems="baseline"
                        justifyContent="center"
                      >
                        <Image
                          src={trophyIcon}
                          alt="teddy dao"
                          zIndex={1}
                          position="relative"
                          maxHeight="24px"
                        />
                      </Th>
                      <Th>Address</Th>
                      <Th isNumeric>Total Donations(GLMR)</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {!accountIsRanked && (
                      <Tr>
                        <Td
                          display="flex"
                          alignItems="baseline"
                          justifyContent="center"
                          isNumeric
                        >
                          Unranked
                        </Td>
                        <Td fontWeight="bold">You</Td>
                        <Td isNumeric fontWeight="bold">
                          {_accountQuery &&
                          _accountQuery.data &&
                          _accountQuery.data.account?.totalDonations
                            ? formatUnits(
                                _accountQuery.data.account?.totalDonations
                              )
                            : 0}
                        </Td>
                      </Tr>
                    )}
                    {_accountsQueryByDonations?.data?.accounts?.length > 0 &&
                      _accountsQueryByDonations.data.accounts.map(
                        (account: Account, key: number) => {
                          const isOwner =
                            getAddress(account.id) === userAddress &&
                            getAddress(userAddress);
                          const style: SystemStyleObject = {
                            // border: isOwner ? `2px solid` : `none`,
                            bg: isOwner ? `brand.500` : `none`,
                            color: isOwner ? `white` : `inherit`,
                            td: {
                              border: `none`,
                            },
                          };

                          return (
                            <Tr key={key} sx={style}>
                              <Td
                                display="flex"
                                alignItems="baseline"
                                justifyContent="center"
                                isNumeric
                              >
                                {key + 1}
                              </Td>
                              <Td fontWeight="bold">
                                {isOwner ? "You" : shortenAddress(account.id)}
                              </Td>
                              <Td isNumeric fontWeight="bold">
                                {formatUnits(account.totalDonations)}
                              </Td>
                            </Tr>
                          );
                        }
                      )}
                  </Tbody>
                </Table>
              </TableContainer>{" "}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </Box>
  );
};

import { useEffect } from "react";
import { useEthers } from "@usedapp/core";
import { useAppDispatch, useAppSelector } from "./hooks";
import { setActiveAccount } from "./state/slices/account";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { setAlertModal } from "./state/slices/application";
import classes from "./App.module.css";
import "../src/css/globals.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AlertModal from "./components/Modal";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import AuctionPage from "./pages/Auction";
import LandingPage from "./pages/Landing";
import NotFoundPage from "./pages/NotFound";
import Playground from "./pages/Playground";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import { MyTeddies } from "./pages/MyTeddies";
import { CharityLeaderboard } from "./pages/CharityLeaderboard";
import { Box } from "@chakra-ui/react";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { CharityList } from "./pages/CharityList";
import { CharityListSearchable } from "./pages/CharityListSearchable";

import { useActivateElliptic } from "./hooks/useActivateElliptic";
import { BridgePage } from "./pages/Bridge";

function App() {
  const { account } = useEthers();
  useActivateElliptic({ address: account });
  
  const dispatch = useAppDispatch();
  dayjs.extend(relativeTime);

  useEffect(() => {
    // Local account array updated
    dispatch(setActiveAccount(account));
  }, [account, dispatch]);

  const alertModal = useAppSelector((state) => state.application.alertModal);

  return (
    <Box bg="white" color="var(--brand-cool-dark-text)">
      <div className={`${classes.wrapper}`}>
        {alertModal.show && (
          <AlertModal
            title={alertModal.title}
            content={<p>{alertModal.message}</p>}
            onDismiss={() =>
              dispatch(setAlertModal({ ...alertModal, show: false }))
            }
          />
        )}
        <BrowserRouter>
          <NavBar />
          <Box>
            <ScrollToTop />
            <Switch>
              <Route exact path="/bridge" component={BridgePage} />
              <Route
                exact
                path="/teddy/:id"
                render={(props) => (
                  <AuctionPage
                    initialAuctionId={Number(props.match.params.id)}
                  />
                )}
              />
              <Route exact path="/auction" component={AuctionPage} />
              <Route exact path="/charities" component={CharityListSearchable} />
              <Route exact path="/leaderboard" component={CharityLeaderboard} />
              <Route exact path="/my-teddies" component={MyTeddies} />
              <Route exact path="/" component={LandingPage} />
              <Route exact path="/toy-box" component={Playground} />
              <Route component={NotFoundPage} />
            </Switch>
          </Box>
          <Footer />
        </BrowserRouter>
      </div>
    </Box>
  );
}

export default App;

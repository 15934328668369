export const UpperBgWave = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1438"
      height="43"
      fill="none"
      {...props}
    >
      <path
        fill="#03193F"
        d="M0 43V12l79.5-2 97-9.5L229 12 489 .5 646.5 10 711 .5 1017.5 18l220.5-8 103 8 96.5-8v33H0Z"
      />
    </svg>
  );
}
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPartData = exports.getTeddySeedFromBlockHash = exports.getPseudorandomPart = exports.shiftRightAndCast = exports.getRandomTeddySeed = exports.getTeddyData = void 0;
const solidity_1 = require("@ethersproject/solidity");
const bignumber_1 = require("@ethersproject/bignumber");
const image_data_json_1 = require("./image-data.json");
const { bodies, headAccessories, bodyAccessories, faces } = image_data_json_1.images;
/**
 * Get encoded part and background information using a Teddy seed
 * @param seed The Teddy seed
 */
const getTeddyData = (seed) => {
    return {
        parts: [
            bodies[seed.body],
            headAccessories[seed.headAccessory],
            bodyAccessories[seed.bodyAccessory],
            faces[seed.face],
        ],
        background: image_data_json_1.bgcolors[seed.background],
    };
};
exports.getTeddyData = getTeddyData;
/**
 * Generate a random Teddy seed
 * @param seed The Teddy seed
 */
const getRandomTeddySeed = () => {
    return {
        background: Math.floor(Math.random() * image_data_json_1.bgcolors.length),
        body: Math.floor(Math.random() * bodies.length),
        headAccessory: Math.floor(Math.random() * headAccessories.length),
        bodyAccessory: Math.floor(Math.random() * bodyAccessories.length),
        face: Math.floor(Math.random() * faces.length),
    };
};
exports.getRandomTeddySeed = getRandomTeddySeed;
/**
 * Emulate bitwise right shift and uint cast
 * @param value A Big Number
 * @param shiftAmount The amount to right shift
 * @param uintSize The uint bit size to cast to
 */
const shiftRightAndCast = (value, shiftAmount, uintSize) => {
    const shifted = bignumber_1.BigNumber.from(value).shr(shiftAmount).toHexString();
    return `0x${shifted.substring(shifted.length - uintSize / 4)}`;
};
exports.shiftRightAndCast = shiftRightAndCast;
/**
 * Emulates the TeddySeeder.sol methodology for pseudorandomly selecting a part
 * @param pseudorandomness Hex representation of a number
 * @param partCount The number of parts to pseudorandomly choose from
 * @param shiftAmount The amount to right shift
 * @param uintSize The size of the unsigned integer
 */
const getPseudorandomPart = (pseudorandomness, partCount, shiftAmount, uintSize = 48) => {
    const hex = (0, exports.shiftRightAndCast)(pseudorandomness, shiftAmount, uintSize);
    return bignumber_1.BigNumber.from(hex).mod(partCount).toNumber();
};
exports.getPseudorandomPart = getPseudorandomPart;
/**
 * Emulates the TeddySeeder.sol methodology for generating a Teddy seed
 * @param teddyId The Teddy tokenId used to create pseudorandomness
 * @param blockHash The block hash use to create pseudorandomness
 */
const getTeddySeedFromBlockHash = (teddyId, blockHash) => {
    const pseudorandomness = (0, solidity_1.keccak256)(['bytes32', 'uint256'], [blockHash, teddyId]);
    return {
        background: (0, exports.getPseudorandomPart)(pseudorandomness, image_data_json_1.bgcolors.length, 0),
        body: (0, exports.getPseudorandomPart)(pseudorandomness, bodies.length, 48),
        bodyAccessory: (0, exports.getPseudorandomPart)(pseudorandomness, bodyAccessories.length, 96),
        headAccessory: (0, exports.getPseudorandomPart)(pseudorandomness, headAccessories.length, 144),
        face: (0, exports.getPseudorandomPart)(pseudorandomness, faces.length, 192),
    };
};
exports.getTeddySeedFromBlockHash = getTeddySeedFromBlockHash;
/**
 * Get encoded part information for one trait
 * @param partType The label of the part type to use
 * @param partIndex The index within the image data array of the part to get
 */
const getPartData = (partType, partIndex) => {
    const part = partType;
    return image_data_json_1.images[part][partIndex].data;
};
exports.getPartData = getPartData;

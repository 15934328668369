import { BigNumber } from 'ethers';
import { formatEther } from 'ethers/lib/utils';
import React from 'react';

const TruncatedAmount: React.FC<{ amount: BigNumber }> = props => {
  const { amount } = props;

  const eth = Number(formatEther(amount)).toFixed(2);
  return <>{`${eth}`} GLMR</>;
};
export default TruncatedAmount;

import {
  ContractAddresses as TeddyContractAddresses,
  getContractAddressesForChainOrThrow,
} from "@luckyfriday/sdk";
import { ChainId } from "@usedapp/core";

interface ExternalContractAddresses {
  lidoToken: string | undefined;
}

export type ContractAddresses = TeddyContractAddresses &
  ExternalContractAddresses;

interface AppConfig {
  jsonRpcUri: string;
  wsRpcUri: string;
  subgraphApiUri: string;
  enableHistory: boolean;
}

type SupportedChains = ChainId.MoonbaseAlpha | ChainId.Moonbeam;

interface CacheBucket {
  name: string;
  version: string;
}

export const cache: Record<string, CacheBucket> = {
  seed: {
    name: "seed",
    version: "v1",
  },
  ens: {
    name: "ens",
    version: "v1",
  },
};

export const cacheKey = (
  bucket: CacheBucket,
  ...parts: (string | number)[]
) => {
  return [bucket.name, bucket.version, ...parts].join("-").toLowerCase();
};

console.log(process.env.REACT_APP_CHAIN_ID, "this");
export const CHAIN_ID: SupportedChains = parseInt(
  process.env.REACT_APP_CHAIN_ID ?? "1287"
);

export const ETHERSCAN_API_KEY = process.env.REACT_APP_ETHERSCAN_API_KEY ?? "";

const INFURA_PROJECT_ID = process.env.REACT_APP_INFURA_PROJECT_ID;
export const WALLET_CONNECT_PROJECT_ID = "4749b22ca3dba81b8f0b356fd13e1609";

export const createNetworkHttpUrl = (network: string): string => {
  const custom = process.env[`REACT_APP_${network.toUpperCase()}_JSONRPC`];
  return custom || `https://${network}.infura.io/v3/${INFURA_PROJECT_ID}`;
};

export const createNetworkWsUrl = (network: string): string => {
  const custom = process.env[`REACT_APP_${network.toUpperCase()}_WSRPC`];
  return custom || `wss://${network}.infura.io/ws/v3/${INFURA_PROJECT_ID}`;
};

const app: Record<SupportedChains, AppConfig> = {
  [ChainId.MoonbaseAlpha]: {
    jsonRpcUri: "https://moonbase-alpha.public.blastapi.io",
    wsRpcUri: "wss://wss.api.moonbase.moonbeam.network",
    subgraphApiUri:
      "https://api.thegraph.com/subgraphs/name/kenjicncr/teddy-dao-subgraph-test-v2",
    enableHistory: process.env.REACT_APP_ENABLE_HISTORY === "true",
  },
  [ChainId.Moonbeam]: {
    jsonRpcUri: "https://moonbeam.public.blastapi.io",
    wsRpcUri: "wss://moonbeam.public.blastapi.io",
    subgraphApiUri:
      "https://api.goldsky.com/api/public/project_cltopczbdi5qb01t70ay69s2a/subgraphs/teddydao/1.0.0/gn",
    enableHistory: process.env.REACT_APP_ENABLE_HISTORY === "true",
  },
};

const externalAddresses: Record<SupportedChains, ExternalContractAddresses> = {
  [ChainId.MoonbaseAlpha]: {
    lidoToken: undefined,
  },
  [ChainId.Moonbeam]: {
    lidoToken: undefined,
  },
};

const getAddresses = (): ContractAddresses => {
  let teddyAddresses = {} as TeddyContractAddresses;
  try {
    teddyAddresses = getContractAddressesForChainOrThrow(CHAIN_ID);
  } catch {}
  return { ...teddyAddresses, ...externalAddresses[CHAIN_ID] };
};

const config = {
  app: app[CHAIN_ID],
  addresses: getAddresses(),
};

console.log({ config })

export default config;

import { useQuery } from "@tanstack/react-query";
import { useLocalStorage } from "react-use";
type UseActivateElliptic = {
  address: string | null | undefined;
};

export const useActivateElliptic = ({
  address
}: UseActivateElliptic) => {

  const [amlScore, setAmlScore] = useLocalStorage("amlScore", null);


  const query = useQuery({
    queryKey: [address, "elliptic"],
    queryFn: () => getEllipticValue(address),
    enabled: !!address,
    onSuccess: (data) => {
      console.log("screened address", address);
      setAmlScore(data.risk_score)
    },
    onError: () => {
      console.log("error screening", address);
      setAmlScore(null)
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  });

  return query
};

async function getEllipticValue(address: string | null | undefined) {
  const url = '/.netlify/functions/screen-address';

  // Create the JSON body with address and value
  const requestBody = JSON.stringify({
    address: address
  });

  try {
    console.log({ requestBody });


    const response = await fetch(url, {
      method: 'POST',
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Parse the response JSON
    const data = await response.json();

    // Handle the response data here
    console.log('Response:', data);

    return data;
  } catch (error: any) {
    // Handle any errors that occurred during the request
    console.error('Error:', error.message);
    throw error; // You can choose to re-throw the error or handle it as needed
  }
}

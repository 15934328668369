import { useAppSelector } from "../../hooks";
import classes from "./NavBar.module.css";
import logo from "../../assets/teddy-logo-horizontal.png";
import { Link, useHistory } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import { NavHashLink } from "react-router-hash-link";
import { ExternalURL, externalURL } from "../../utils/externalURL";
import { NavBarButtonStyle } from "../NavBarButton";
import NavWallet from "../NavWallet";
import { Trans } from "@lingui/macro";
import React, { useState } from "react";
// import NavLocaleSwitcher from '../NavLocaleSwitcher';
import { NavGetGLMR } from "../NavGetGLMR";
import { Badge, Box, Container, IconButton, Text, useDisclosure } from "@chakra-ui/react";
import { MobileNav } from "./MobileNav/MobileNav";
import { GiHamburgerMenu } from "react-icons/gi";

const NavBar = () => {
  const activeAccount = useAppSelector((state) => state.account.activeAccount);
  // const stateBgColor = useAppSelector(state => state.application.stateBackgroundColor);
  const isCool = useAppSelector((state) => state.application.isCoolBackground);
  const history = useHistory();
  // const ethBalance = useEtherBalance(config.addresses.teddyDaoExecutor);
  // const treasuryBalance = ethBalance && lidoBalanceAsETH && ethBalance.add(lidoBalanceAsETH);
  // const daoEtherscanLink = buildEtherscanHoldingsLink(config.addresses.teddyDaoExecutor);
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const useStateBg =
    history.location.pathname.includes("/teddy/") ||
    history.location.pathname.includes("/auction/") ||
    history.location.pathname.includes("/auction");

  const nonWalletButtonStyle = !useStateBg
    ? NavBarButtonStyle.WHITE_INFO
    : isCool
    ? NavBarButtonStyle.COOL_INFO
    : NavBarButtonStyle.WARM_INFO;

  const closeNav = () => setIsNavExpanded(false);

  const mobileNavDisclosure = useDisclosure();

  return (
    <Box
      position={["relative", "relative", "relative", "absolute"]}
      zIndex={99}
      width="100%"
      bg={["white", "white", "white", "transparent"]}
    >
      <Navbar
        expand="xl"
        style={{ position: `relative`, zIndex: 99 }}
        className={classes.navBarCustom}
        expanded={isNavExpanded}
      >
        <Container
          maxWidth="8xl"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <div className={classes.brandAndTreasuryWrapper}>
            <Navbar.Brand as={Link} to="/" className={classes.navBarBrand}>
              <img
                src={logo}
                className={classes.navBarLogo}
                alt="Teddy DAO logo"
              />
            </Navbar.Brand>
          </div>

          <MobileNav
            isOpen={mobileNavDisclosure.isOpen}
            onClose={mobileNavDisclosure.onClose}
          />

          <Box
            sx={{
              display: ["block", "block", "block", "block", "none"],
            }}
          >
            <IconButton
              variant="brand"
              onClick={mobileNavDisclosure.onOpen}
              icon={<GiHamburgerMenu />}
              aria-label="Open menu"
            />
          </Box>
          <Navbar.Collapse className="justify-content-end">
            <Nav.Link
              to={`/#how-it-works`}
              as={NavHashLink}
              className={classes.teddysNavLink}
              onClick={closeNav}
            >
              How it works
            </Nav.Link>

            <Nav.Link
              href={externalURL(ExternalURL.gitbook)}
              className={classes.teddysNavLink}
              target="_blank"
              rel="noreferrer"
              onClick={closeNav}
            >
              <Trans>Docs</Trans>
            </Nav.Link>
            <Nav.Link
              href={externalURL(ExternalURL.discord)}
              className={classes.teddysNavLink}
              target="_blank"
              rel="noreferrer"
              onClick={closeNav}
            >
              <Trans>Community</Trans>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/charities"
              className={classes.teddysNavLink}
              onClick={closeNav}
            >
              <Trans>Charities</Trans>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/leaderboard"
              className={classes.teddysNavLink}
              onClick={closeNav}
            >
              <Trans>Leaderboard</Trans>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/toy-box"
              className={classes.teddysNavLink}
              onClick={closeNav}
            >
              <Trans>Toy Box</Trans>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/bridge"
              className={classes.teddysNavLink}
              onClick={closeNav}
            >
              <Box position="relative">
                <Text position="relative">Bridge</Text>
                <Text
                  fontSize="12px"
                  letterSpacing="1.2"
                  fontWeight="bold"
                  fontFamily="heading"
                  textTransform="uppercase"
                  position="absolute"
                  top="0"
                  right="0"
                  transform="translateX(calc(100% - 4px))"
                  color="accent.500"
                >
                  New
                </Text>
              </Box>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/auction"
              className={classes.teddysNavLink}
              onClick={closeNav}
            >
              <Trans>Auction</Trans>
            </Nav.Link>

            <div style={{ marginRight: `8px` }}>
              <NavGetGLMR />
            </div>
            <Box minWidth="150px">
              <NavWallet
                address={activeAccount || "0"}
                buttonStyle={nonWalletButtonStyle}
              />
            </Box>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Box>
  );
};

export default NavBar;

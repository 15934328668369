export enum ExternalURL {
  discord,
  twitter,
  notion,
  discourse,
  gitbook
}

export const externalURL = (externalURL: ExternalURL) => {
  switch (externalURL) {
    case ExternalURL.discord:
      return "https://discord.com/invite/QnFYE9hqc9";
    case ExternalURL.twitter:
      return "https://twitter.com/teddydaonft";
    case ExternalURL.notion:
      return "https://nouns.notion.site/Explore-Nouns-a2a9dceeb1d54e10b9cbf3f931c2266f";
    case ExternalURL.discourse:
      return "https://discourse.nouns.wtf/";
    case ExternalURL.gitbook:
      return "https://lucky-friday.gitbook.io/teddy-dao";
  }
};

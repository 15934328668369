import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  dialog: {
    border: `2px solid`,
    borderColor: `#1a1730`,
    borderRadius: "md",
    boxShadow: `1px 1px 0px #1a1730,
                2px 2px 0px #1a1730,
                3px 3px 0px #1a1730,
                4px 4px 0px #1a1730,
                5px 5px 0px #1a1730,
                6px 6px 0px #1a1730`,
  },
  closeButton: {
    bg: `accent.500`,
    borderRadius: `4px`,
    color: `brand.500`
  }
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
});

import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Image,
  ModalBody,
  Text,
  Button,
  Stack,
  FormLabel,
  HStack,
  Input,
  IconButton,
} from "@chakra-ui/react";

import { SiJustgiving } from "react-icons/si";
import { useEthers } from "@usedapp/core";
import { TransactionStatus } from "@usedapp/core/dist/cjs/src";
import { BigNumber } from "ethers";
import { useState } from "react";
import { useLocalStorage } from "react-use";
import logo from "../../assets/teddy-logo-horizontal.png";
import { CHAIN_ID } from "../../config";
import { useAppSelector } from "../../hooks";
import { BidObject, CharityObject } from "../BidSelectorModal";
import { StandaloneTeddyWithSeed } from "../StandaloneTeddy";
import TruncatedAmount from "../TruncatedAmount";
import { WalletConnectModal } from "../WalletConnectModal";

interface CharityBidModalProps {
  isOpen: boolean;
  onClose: () => void;
  charity: CharityObject;
  teddyId: BigNumber | undefined;
  placeBidState: TransactionStatus;
  onPlaceBid: (bid: BidObject) => void;
  isPlaceBidDisabled: boolean;
  donorName: string;
  bidValue: string;
  minBid: number;
  onChangeBidValue: (value: string) => void;
  onChangeDonorName: (value: string) => void;
  currentHighestBid: BigNumber | undefined;
}

export const CharityBidModal = ({
  isOpen,
  onClose,
  charity,
  teddyId,
  onPlaceBid,
  isPlaceBidDisabled,
  placeBidState,
  bidValue,
  minBid,
  donorName,
  onChangeBidValue,
  onChangeDonorName,
  currentHighestBid,
}: CharityBidModalProps) => {
  const activeAccount = useAppSelector((state) => state.account.activeAccount);
  const { account, chainId, switchNetwork } = useEthers();
  const [showConnectModal, setShowConnectModal] = useState(false);

  const hideModalHandler = () => {
    setShowConnectModal(false);
  };

  const isWrongNetwork = Number(CHAIN_ID) !== chainId;

  const [amlScore, setAmlScore] = useLocalStorage("amlScore");
  const currentAmlScore = amlScore as any;
  const isAmlFail = currentAmlScore && currentAmlScore >= 10;

  const handleConfirmBid = () => {
    onPlaceBid &&
      onPlaceBid({
        charityId: charity.charityId,
        charityName: charity.charityName,
        donorName: donorName,
        value: bidValue,
      });
  };

  const renderBidButton = () => {
    if (account) {
      if (isWrongNetwork) {
        return (
          <Button
            colorScheme="brand"
            onClick={async () => {
              await switchNetwork(CHAIN_ID);
            }}
            style={{
              width: `200px`,
            }}
          >
            Switch network
          </Button>
        );
      } else if (isAmlFail) {
        return (
          <Button colorScheme="brand" disabled={true} width="100%">
            Cannot bid due to AML compliance. Please contact us on Discord.
          </Button>
        );
      } else {
        return (
          <Button
            colorScheme="brand"
            onClick={handleConfirmBid}
            disabled={isPlaceBidDisabled}
            isLoading={
              placeBidState.status === "Mining" ||
              placeBidState.status === "PendingSignature"
            }
            style={{
              width: `200px`,
            }}
          >
            Place bid
          </Button>
        );
      }
    } else {
      return (
        <Button
          colorScheme="brand"
          onClick={() => setShowConnectModal(true)}
          style={{
            width: `200px`,
          }}
        >
          Connect Wallet
        </Button>
      );
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="blackAlpha.800" />
        <ModalContent minWidth={[null, null, "3xl"]} mx={4}>
          <Flex>
            <Box width="100%">
              <Container margin={0} p={0} maxWidth="100%">
                <ModalHeader flex={1} pt={4} pb={2}>
                  <Heading as="h5" fontFamily="heading" fontSize="2xl">
                    <Image
                      src={logo}
                      display="inline-block"
                      alt="teddy dao logo"
                      width="128px"
                      ml={-4}
                    />
                  </Heading>
                </ModalHeader>
                <Divider borderColor="gray.300" />

                <ModalBody pt={4} display="grid" gap={4}>
                  <Box
                    display="grid"
                    alignItems="center"
                    justifyItems={"center"}
                    gridTemplateColumns={["1fr", "150px 1fr", "150px 1fr"]}
                    gap={8}
                  >
                    <Box
                      width="100%"
                      alignItems="center"
                      display="flex"
                      flexDirection="column"
                      justifyContent={"center"}
                    >
                      <Box
                        maxWidth="150px"
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        justifyContent={"center"}
                        alignItems="center"
                      >
                        {teddyId && (
                          <StandaloneTeddyWithSeed
                            teddyId={teddyId}
                            shouldLinkToProfile={false}
                            onLoadSeed={() => {}}
                          />
                        )}
                      </Box>
                      <Box textAlign="center">
                        <Text>
                          Current bid:{" "}
                          {currentHighestBid !== undefined ? (
                            <Text fontWeight="bold">
                              <TruncatedAmount amount={currentHighestBid!} />
                            </Text>
                          ) : (
                            `n/a`
                          )}
                        </Text>
                      </Box>
                    </Box>
                    <Box>
                      <Heading
                        as="h5"
                        fontFamily="heading"
                        fontSize="2xl"
                        mb={4}
                        lineHeight={1}
                      >
                        Bid for{" "}
                        <Text color="accent.500" as="span">
                          {charity.charityName}
                        </Text>
                      </Heading>

                      <Text fontSize="sm" height="150px" overflow="scroll">
                        {charity.charityDescription}
                      </Text>
                    </Box>
                  </Box>
                  <Box>
                    <Box py={2} pb={8}>
                      <Stack
                        flexDirection={["column", "column", "row"]}
                        alignItems={["flex-end", "flex-end"]}
                        justifyContent="space-between"
                        width="100%"
                      >
                        <HStack
                          spacing={[0, 0, 4]}
                          flexDirection={["column", "column", "row"]}
                          width="100%"
                          mr={[0, 0, 4]}
                        >
                          <Stack spacing={0} pb={[4, 4, 0]} width="100%">
                            <FormLabel fontWeight="bold">Donor Name</FormLabel>
                            <Input
                              type="text"
                              isRequired
                              value={donorName}
                              onChange={(e) =>
                                onChangeDonorName(e.target.value)
                              }
                            />
                          </Stack>
                          <Stack spacing={0} width="100%">
                            <FormLabel fontWeight="bold">Bid</FormLabel>
                            <Input
                              type="number"
                              value={bidValue.toString()}
                              placeholder={`${minBid} GLMR or more`}
                              onChange={(e) => {
                                onChangeBidValue(e.target.value);
                              }}
                            />
                          </Stack>
                        </HStack>
                        {renderBidButton()}
                      </Stack>
                    </Box>
                  </Box>
                </ModalBody>
              </Container>
            </Box>
            <ModalCloseButton />
          </Flex>
        </ModalContent>
      </Modal>
      {showConnectModal && activeAccount === undefined && (
        <WalletConnectModal
          onClose={hideModalHandler}
          isOpen={showConnectModal}
        />
      )}
    </>
  );
};

import classes from "./Section.module.css";
import { Container, Row } from "react-bootstrap";
import { ReactChildren } from "../../utils/types";

const Section: React.FC<{
  fullWidth: boolean;
  className?: string;
  id?: string;
  children: ReactChildren;
}> = (props) => {
  const { fullWidth, className, children, id } = props;
  return (
    <section className={`${classes.container} ${className}`} id={id}>
      <Container fluid={fullWidth ? true : "lg"}>
        <Row className="align-items-center">{children}</Row>
      </Container>
    </section>
  );
};
export default Section;

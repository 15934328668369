import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OnDisplayAuctionState {
  lastAuctionTeddyId: number | undefined;
  onDisplayAuctionTeddyId: number | undefined;
}

const initialState: OnDisplayAuctionState = {
  lastAuctionTeddyId: undefined,
  onDisplayAuctionTeddyId: undefined,
};

const onDisplayAuction = createSlice({
  name: 'onDisplayAuction',
  initialState: initialState,
  reducers: {
    setLastAuctionTeddyId: (state, action: PayloadAction<number>) => {
      state.lastAuctionTeddyId = action.payload;
    },
    setOnDisplayAuctionTeddyId: (state, action: PayloadAction<number>) => {
      state.onDisplayAuctionTeddyId = action.payload;
    },
    setPrevOnDisplayAuctionTeddyId: state => {
      if (!state.onDisplayAuctionTeddyId) return;
      if (state.onDisplayAuctionTeddyId === 0) return;
      state.onDisplayAuctionTeddyId = state.onDisplayAuctionTeddyId - 1;
    },
    setNextOnDisplayAuctionTeddyId: state => {
      if (state.onDisplayAuctionTeddyId === undefined) return;
      if (state.lastAuctionTeddyId === state.onDisplayAuctionTeddyId) return;
      state.onDisplayAuctionTeddyId = state.onDisplayAuctionTeddyId + 1;
    },
  },
});

export const {
  setLastAuctionTeddyId,
  setOnDisplayAuctionTeddyId,
  setPrevOnDisplayAuctionTeddyId,
  setNextOnDisplayAuctionTeddyId,
} = onDisplayAuction.actions;

export default onDisplayAuction.reducer;

import { FaNewspaper } from "react-icons/fa";
import {
  BentoGrid,
  BentoGridItem,
} from "../../../components/BentoGrid/BentoGrid";

import cryptoSlate from "../../../assets/news/crypto-slate.jpg";
import cryptoSlateLogo from "../../../assets/news/cryptoslate-logo.png";
import yahooLogo from "../../../assets/news/yahoo-finance-logo.png";
import yahoo from "../../../assets/news/yahoo-finance.jpg";
import xLogo from "../../../assets/news/x-logo.svg";

import { HStack, Image } from "@chakra-ui/react";

export function FeaturedOnSection() {
  return (
    <section>
      <h2 className="text-4xl font-bold text-center mb-4">Featured on</h2>
      <BentoGrid className="mx-auto">
        {items.map((item, i) => (
          <BentoGridItem
            key={i}
            title={item.title}
            description={item.description}
            header={item.header}
            icon={item.icon}
            url={item.url}
            className={i === 3 || i === 6 ? "md:col-span-2" : ""}
          />
        ))}
      </BentoGrid>
    </section>
  );
}
const Banner = ({ src }: { src: string }) => (
  <div className="flex flex-1 w-full h-full min-h-[6rem] rounded-xl bg-gradient-to-br from-neutral-200 dark:from-neutral-900 dark:to-neutral-800 to-neutral-100">
    <Image src={src} className="w-full h-full object-cover" />
  </div>
);
const items = [
  {
    title:
      "Polkadot-based Teddy DAO seeks to onboard charitable donors through Web3 education",
    description:
      "At the crossroads of charity and technology, Teddy DAO aims to introduce Web3 to conventional donors.",
    header: <Banner src={cryptoSlate} />,
    icon: <Image src={cryptoSlateLogo} className="h-4" />,
    url: "https://cryptoslate.com/polkadot-based-teddy-dao-seeks-to-onboard-charitable-donors-through-web3-education/",
  },
  {
    title:
      "TeddyDAO Partners with Leading Web3 Influencers for a Charitable Christmas Giving Campaign",
    description:
      "Participating influencers will promote TeddyDAO auctions for four weeks while their communities will complete social challenges to increase Just Giving charities donations. Exclusive prizes have also been donated by some influencers, up for grabs for Teddy NFT auction winners.",
    header: <Banner src={yahoo} />,
    icon: <Image src={yahooLogo} className="h-4" />,
    url: "https://finance.yahoo.com/news/teddydao-partners-leading-web3-influencers-160500119.html?guccounter=1",
  },
  {
    title: "Human Protocol",
    description: `#Web3 is all about helping each other! 😊

That's why we're delighted to announce our partnership with 
@TeddyDaoNFT
, as the official charity partner of #Newconomics2023!✨

To find out more about their vision, `,
    icon: <Image className="h-4" src={xLogo} />,
    url: "https://x.com/human_protocol/status/1722303087308652593?s=20",
  },
];

import { Col } from "react-bootstrap";
import { Image, Box, chakra, useDisclosure, Tooltip } from "@chakra-ui/react";
import { StandaloneTeddyWithSeed } from "../StandaloneTeddy";
import AuctionActivity from "../AuctionActivity";
import { Row, Container } from "react-bootstrap";
import { setStateBackgroundColor } from "../../state/slices/application";
import { Auction as IAuction } from "../../wrappers/teddyAuction";
import classes from "./Auction.module.css";
import { ITeddySeed } from "../../wrappers/teddyToken";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  setNextOnDisplayAuctionTeddyId,
  setPrevOnDisplayAuctionTeddyId,
} from "../../state/slices/onDisplayAuction";
import { beige, grey } from "../../utils/nounBgColors";

import claw from "../../assets/claw-machine/claw-hyper-long.png";
import clawBox from "../../assets/claw-machine/box-with-pile.png";
// import pattern from "../../assets/background/teddy-pattern.png";

import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect } from "react";
import { AttributesModal } from "../AttributesModal/AttributesModal";
import { CustomTeddyBadge } from "./CustomTeddyBadge";
import { findCustomTeddyById } from "./customTeddy";

export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}

const MotionBox = chakra(motion.div);

interface AuctionProps {
  auction?: IAuction;
}

const Auction: React.FC<AuctionProps> = (props) => {
  const { auction: currentAuction } = props;

  console.log("current teddy id: ", currentAuction?.teddyId);

  const history = useHistory();
  const dispatch = useAppDispatch();
  // let stateBgColor = useAppSelector(state => state.application.stateBackgroundColor);
  const lastTeddyId = useAppSelector(
    (state) => state.onDisplayAuction.lastAuctionTeddyId
  );

  const isSmall = useMediaQuery("(max-width: 767px)");

  const loadedTeddyHandler = (seed: ITeddySeed) => {
    dispatch(setStateBackgroundColor(seed.background === 0 ? grey : beige));
  };

  const prevAuctionHandler = () => {
    // controls.start("animate");
    dispatch(setPrevOnDisplayAuctionTeddyId());
    currentAuction &&
      history.push(`/teddy/${currentAuction.teddyId.toNumber() - 1}`);
  };
  const nextAuctionHandler = () => {
    // controls.start("animate");
    dispatch(setNextOnDisplayAuctionTeddyId());
    currentAuction &&
      history.push(`/teddy/${currentAuction.teddyId.toNumber() + 1}`);
  };

  const attributesModalDisclosure = useDisclosure();

  const teddyContent = currentAuction && (
    <AnimatePresence mode="wait">
      <Tooltip label="View traits">
        <MotionBox
          initial={["hidden"]}
          animate="animate"
          key={currentAuction.teddyId.toString()}
          variants={{
            animate: {
              y: isSmall ? 0 : 300,
              opacity: 1,
              transition: {
                type: "spring",
                damping: 10,
                stiffness: 100,
              },
            },
            hidden: {
              y: 800,
              transition: {
                type: "spring",
                damping: 10,
                stiffness: 100,
              },
            },
          }}
          exit={{
            y: 800,
            transition: {
              type: "spring",
              duration: 1.2,
            },
          }}
          className={classes.teddyWrapper}
          style={{
            transformOrigin: `50% 0`,
            cursor: `zoom-in`,
          }}
          position={["relative", "relative", "absolute"]}
          transform={["translateY(-300px)"]}
        >
          <Box
            position="relative"
            display="flex"
            flexDirection="column"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image
              src={claw}
              alt="claw"
              position="absolute"
              top={0}
              transform={"translateY(calc(-100% + 48px))"}
              maxWidth="64px"
              margin="0 auto"
            />
            <Box
              maxW="256px"
              margin="0 auto"
              width="100%"
              onClick={attributesModalDisclosure.onOpen}
            >
              <StandaloneTeddyWithSeed
                teddyId={currentAuction.teddyId}
                onLoadSeed={loadedTeddyHandler}
                shouldLinkToProfile={false}
              />
            </Box>
          </Box>
        </MotionBox>
      </Tooltip>
    </AnimatePresence>
  );

  const currentAuctionActivityContent = currentAuction &&
    lastTeddyId !== undefined && (
      <Box>
        <AuctionActivity
          auction={currentAuction}
          isFirstAuction={currentAuction.teddyId.eq(0)}
          isLastAuction={currentAuction.teddyId.eq(lastTeddyId)}
          onPrevAuctionClick={prevAuctionHandler}
          onNextAuctionClick={nextAuctionHandler}
          displayGraphDepComps={true}
        />
      </Box>
    );

  const currentCustomTeddy = currentAuction
    ? findCustomTeddyById(currentAuction.teddyId.toNumber())
    : null;

  return (
    <Box sx={{ position: `relative` }}>
      <div className={classes.wrapper}>
        <Container fluid="xl">
          <Row>
            <Col lg={{ span: 6 }} className={classes.teddyContentCol}>
              <Box width="100%" minH="300px" position="relative">
                {currentCustomTeddy && (
                  <Box
                    ml={[0, 0, -24]}
                    position="absolute"
                    width="100%"
                    top={["-80px", "-80px", 0]}
                  >
                    <CustomTeddyBadge
                      id={currentCustomTeddy.id}
                      link={currentCustomTeddy.link}
                      message={currentCustomTeddy.message}
                      sponsorName={currentCustomTeddy.sponsorName}
                      logo={currentCustomTeddy.logo}
                    />
                  </Box>
                )}
                <Box
                  pb={[0, 0, 0]}
                  display="flex"
                  width="100%"
                  maxWidth="400px"
                  margin={["0 auto", "0 auto", "unset"]}
                  position="relative"
                >
                  {teddyContent}
                </Box>
              </Box>
            </Col>
            <Col
              lg={{ span: 6 }}
              className={classes.auctionActivityCol}
              style={{ position: `relative`, zIndex: 1 }}
            >
              {currentAuction && currentAuctionActivityContent}
            </Col>
            <Box
              left={0}
              marginTop={[0, 0, 0, -48]}
              display={["none", "none", "none", "grid"]}
              gridTemplateColumns={"50% 50%"}
              zIndex={0}
              position="relative"
            >
              <Image
                src={clawBox}
                alt="teddy pile"
                width="100%"
                maxWidth="600px"
                margin="0 auto"
                marginLeft={[0, 0, -24]}
              />
              <Box />
            </Box>
          </Row>
        </Container>
      </div>
      {currentAuction?.teddyId && attributesModalDisclosure.isOpen && (
        <AttributesModal
          teddyId={currentAuction?.teddyId}
          isOpen={attributesModalDisclosure.isOpen}
          onClose={attributesModalDisclosure.onClose}
        />
      )}
    </Box>
  );
};

export default Auction;

/**
 * Converts a UTC time string to the local time of the user's browser.
 * @param utcTime - The UTC time string in ISO format.
 * @returns The local time as a string.
 */
export function convertUtcToLocalTime(utcTime: string): string {
  // Parse the UTC time string to a Date object
  const utcDate = new Date(utcTime);

  // Check if the parsed date is valid
  if (isNaN(utcDate.getTime())) {
    throw new Error("Invalid UTC time string");
  }

  // Get the local time string with time zone
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZoneName: "short",
  };
  const localTimeString = utcDate.toLocaleTimeString(undefined, options);

  return localTimeString;
}

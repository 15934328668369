// import Auction from "../../components/Auction";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setOnDisplayAuctionTeddyId } from "../../state/slices/onDisplayAuction";
import { push } from "connected-react-router";
import { teddyPath } from "../../utils/history";
import useOnDisplayAuction from "../../wrappers/onDisplayAuction";
import { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { navbarHeight } from "../../theme/constants";
import Auction from "../../components/Auction";

interface AuctionPageProps {
  initialAuctionId?: number;
}

const AuctionPage: React.FC<AuctionPageProps> = (props) => {
  const { initialAuctionId } = props;
  const onDisplayAuction = useOnDisplayAuction();
  const lastAuctionTeddyId = useAppSelector(
    (state) => state.onDisplayAuction.lastAuctionTeddyId
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (lastAuctionTeddyId === undefined) return;

    if (initialAuctionId !== undefined) {
      // handle out of bounds noun path ids
      if (initialAuctionId > lastAuctionTeddyId || initialAuctionId < 0) {
        dispatch(setOnDisplayAuctionTeddyId(lastAuctionTeddyId));
        dispatch(push(teddyPath(lastAuctionTeddyId)));
      } else {
        if (onDisplayAuction === undefined) {
          // handle regular noun path ids on first load
          dispatch(setOnDisplayAuctionTeddyId(initialAuctionId));
        }
      }
    } else {
      // no noun path id set
      if (lastAuctionTeddyId !== undefined) {
        dispatch(setOnDisplayAuctionTeddyId(lastAuctionTeddyId));
      }
    }
  }, [lastAuctionTeddyId, dispatch, initialAuctionId, onDisplayAuction]);

  return (
    <Box pt={navbarHeight}>
      <Auction auction={onDisplayAuction} />
    </Box>
  );
};
export default AuctionPage;

import classes from "./Footer.module.css";
import { Box, Text, HStack, VStack, Link as ChakraLink } from "@chakra-ui/react";
import { Container } from "react-bootstrap";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { externalURL, ExternalURL } from "../../utils/externalURL";
import Link from "../Link";

const Footer = () => {
  const twitterURL = externalURL(ExternalURL.twitter);
  const discordURL = externalURL(ExternalURL.discord);

  return (
    <Box
      className={classes.wrapper}
      backgroundColor="brand.500"
      color="white"
      zIndex={1}
    >
      <Container className={classes.container}>
        <Box as="footer" className={classes.footerSignature}>
          <VStack justifyContent="center">
            <HStack fontSize="3xl">
              <Link text={<FaDiscord />} url={discordURL} leavesPage={true} />
              <Link text={<FaTwitter />} url={twitterURL} leavesPage={true} />
            </HStack>
            <HStack justifyContent="center" maxWidth="md" margin="0 auto" textAlign="center">
              <Text fontStyle="italic">
                Proudly built by
                <ChakraLink
                  color="accent.500"
                  rel="noopener noreferrer nofollow"
                  href="https://luckyfriday.io"
                  target="_blank"
                  _hover={{
                    color: `accent.500`,
                  }}
                >
                  {" "}
                  Lucky Friday Labs{" "}
                </ChakraLink>
                with{" "}
                <ChakraLink
                  color="accent.500"
                  rel="noopener noreferrer nofollow"
                  href="https://moonbeam.network"
                  target="_blank"
                  _hover={{
                    color: `accent.500`,
                  }}
                >
                  {" "}
                  Moonbeam{" "}
                </ChakraLink>{" "}
                on{" "}
                <ChakraLink
                  color="accent.500"
                  rel="noopener noreferrer nofollow"
                  href="https://globalstake.io"
                  target="_blank"
                  _hover={{
                    color: `accent.500`,
                  }}
                >
                  {" "}
                  GlobalStake web3 infrastructure
                </ChakraLink>{" "}
              </Text>
            </HStack>
          </VStack>
        </Box>
      </Container>
    </Box>
  );
};
export default Footer;

import {
  chakra,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Link,
  LightMode,
} from "@chakra-ui/react";
import React from "react";

type Status = "info" | "warning" | "success" | "error" | "loading" | undefined;
type Variant = "subtle" | "left-accent" | "top-accent" | "solid" | undefined;
type CustomToastProps =  {
  status?: Status;
  variant?: Variant;
  title?: React.ReactNode;
  description?: React.ReactNode;
  link?: string;
  isClosable?: boolean;
  onClose: () => void;
  id: string;
};
export const ToastNotification = (props: CustomToastProps) => {
  const {
    status = "info",
    id,
    title,
    isClosable = true,
    onClose,
    description,
    link,
  } = props;

  const alertId = typeof id !== "undefined" ? `toast-${id}` : undefined;
  const alertTitleId =
    typeof id !== "undefined" ? `toast-${id}-title` : undefined;

  return (
    <LightMode>
      <Alert
        status={status}
        variant="subtle"
        id={alertId}
        alignItems="start"
        borderRadius="md"
        boxShadow="lg"
        paddingEnd={8}
        textAlign="start"
        width="auto"
        aria-labelledby={alertTitleId}
      >
        <AlertIcon />
        <chakra.div flex="1" maxWidth="100%">
          {title && <AlertTitle id={alertTitleId}>{title}</AlertTitle>}
          {description && (
            <AlertDescription display="block">{description}</AlertDescription>
          )}
          {link && (
            <Link href={link} isExternal variant="underlined">
              View on explorer
            </Link>
          )}
        </chakra.div>
        {isClosable && (
          <CloseButton
            size="sm"
            onClick={onClose}
            position="absolute"
            insetEnd={1}
            top={1}
          />
        )}
      </Alert>
    </LightMode>
  );
};

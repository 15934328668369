import { extendTheme } from "@chakra-ui/react";
import { alertAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { modalTheme } from "./components/modal";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    // bg: `brand.100 !important`
  },
});

const alertTheme = defineMultiStyleConfig({ baseStyle });

export const colors = {
  brand: {
    100: "#e7eff3",
    500: "#1a1730",
  },
  accent: {
    500: "#ED7614",
  },
  secondary: {
    500: "#94fdff",
  },
};

export const theme = extendTheme({
  colors,
  useSystemColorMode: false,
  components: {
    Alert: alertTheme,
    Modal: modalTheme,
    Button: {
      variants: {
        brand: {
          bg: "brand.500",
          color: "white",
          _hover: {
            bg: "brand.500",
            color: "accent.500",
          },
        },
      },
    },
  },
  styles: {
    global: {
      body: {
        color: `brand.500`,
      },
    },
  },
});

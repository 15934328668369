import React from "react";
import ShortAddress from "../ShortAddress";
import TruncatedAmount from "../TruncatedAmount";
import { Bid } from "../../utils/types";
import { BigNumber as EthersBN } from "@ethersproject/bignumber";
import { useAppSelector } from "../../hooks";
import { useQuery } from "@apollo/client";
import { bidsByAuctionQuery } from "../../wrappers/subgraph";

const bidItem = (bid: Bid, index: number, classes: any, isCool?: boolean) => {
  
  const bidAmount = (
    <TruncatedAmount
      amount={bid.value}
    />
  );
  /** const date = `${dayjs(bid.timestamp.toNumber() * 1000).format(
    "MMM DD"
  )} at ${dayjs(bid.timestamp.toNumber() * 1000).format("hh:mm a")}`;
  **/

  // const txLink = buildEtherscanTxLink(bid.transactionHash);
  const isMobile = window.innerWidth < 992;

  const charityNameAndIdSplit = bid.charityName.split("_");

  return (
    <li
      key={index}
      style={{
        display: `contents`,
      }}
    >
      <div
        style={{
          display: `flex`,
          alignItems: `center`,
          padding: `16px 16px`,
          fontWeight: `bold`,
          borderTop: `1px solid rgb(167 169 172)`,
        }}
      >
        <div>
          <p style={{ marginBottom: 0 }}>
            {bid.donorName !== "" ? (
              bid.donorName
            ) : (
              <ShortAddress
                address={bid.sender}
                avatar={isMobile ? false : true}
              />
            )}
          </p>
        </div>
      </div>
      <div
        style={{
          display: `flex`,
          alignItems: `center`,
          padding: `16px 16px`,
          fontWeight: `bold`,
          borderTop: `1px solid rgb(167 169 172)`,
        }}
      >
        <p style={{ marginBottom: 0 }}>
          {charityNameAndIdSplit?.length === 2
            ? charityNameAndIdSplit[1]
            : charityNameAndIdSplit[0]}
        </p>
      </div>
      <div
        style={{
          display: `flex`,
          alignItems: `center`,
          padding: `16px 16px`,
          fontWeight: `bold`,
          borderTop: `1px solid rgb(167 169 172)`,
        }}
      >
        {bidAmount}
      </div>
      {/**   <div className={classes.linkSymbol}>
            <a href={txLink} target="_blank" rel="noreferrer">
              <img src={link} width={24} alt="link symbol" />
            </a>
          </div>*/}
    </li>
  );
};

const BidHistory: React.FC<{
  auctionId: string;
  max: number;
  classes?: any;
}> = (props) => {
  const { auctionId, max, classes } = props;
  const isCool = useAppSelector((state) => state.application.isCoolBackground);

  const bidsQuery = useQuery(bidsByAuctionQuery(auctionId));

  console.log({ bidsQuery })

  const bidContent =
    bidsQuery.data && bidsQuery.data.bids.length > 0 &&
    bidsQuery.data.bids
      .map((bid: Bid, i: number) => {
        return bidItem(bid, i, classes, isCool);
      })
      .slice(0, max);

  return (
    <ul className={classes.bidCollection} style={{ rowGap: 0 }}>
      <li style={{ display: `contents` }}>
        <div
          style={{
            display: `flex`,
            alignItems: `center`,
            padding: `8px 16px`,
          }}
        >
          From
        </div>
        <div
          style={{
            display: `flex`,
            alignItems: `center`,
            padding: `8px 16px`,
          }}
        >
          For
        </div>
        <div
          style={{
            display: `flex`,
            alignItems: `center`,
            padding: `8px 16px`,
          }}
        >
          Price
        </div>
      </li>
      {bidContent}
    </ul>
  );
};

export default BidHistory;

import {
  Box,
  Text,
  Image,
  Heading,
  Grid,
  Container,
  VStack,
  HStack,
  Button,
  Flex,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useInfiniteQuery } from "@tanstack/react-query";

import { supabase } from "../../wrappers/supabaseClient";

import { CharitySearch } from "../../components/CharitySearch";
import { useEffect, useState } from "react";
import { CharityBidModal } from "../../components/CharityBidModal/CharityBidModal";
import { useDebounce } from "react-use";
import { BidObject, CharityObject } from "../../components/BidSelectorModal";
import useOnDisplayAuction from "../../wrappers/onDisplayAuction";
import { useCurrentAuction } from "../../hooks/useCurrentAuction";
import { utils, BigNumber as EthersBN } from "ethers";
import { formatEther, parseEther } from "ethers/lib/utils";
import BigNumber from "bignumber.js";
import {
  AuctionHouseContractFunction,
  useAuctionMinBidIncPercentage,
} from "../../wrappers/teddyAuction";
import { useContractFunction, useEthers } from "@usedapp/core";
import { useLocalStorage } from "react-use";
import { connectContractToSigner } from "@usedapp/core/dist/cjs/src/hooks";
import { TeddyAuctionHouseFactory } from "@luckyfriday/sdk";
import config from "../../config";
import { TransactionStatus } from "@usedapp/core/dist/cjs/src";
import { ToastNotification } from "../../components/ToastNotification";

const computeMinimumNextBid = (
  currentBid: EthersBN,
  minBidIncPercentage: EthersBN | undefined
): EthersBN => {
  if (!minBidIncPercentage) {
    return EthersBN.from(0);
  }

  const percentage = Number(minBidIncPercentage.toString()) / 100 + 1;
  const value = formatEther(currentBid);
  const nextBid = percentage * Number(value);

  const nextBidClean = nextBid > 0.01 ? nextBid : 0.01;

  return parseEther(nextBidClean.toString());
};

export const minBidEth = (minBid: EthersBN): string => {
  if (minBid.isZero()) {
    return "0.01";
  }

  const eth = utils.formatEther(minBid);
  return new BigNumber(eth).toFixed(2, BigNumber.ROUND_CEIL);
};

const currentBid = (value: string) => {
  if (value !== "") {
    return utils.parseEther(value);
  } else {
    return EthersBN.from(0);
  }
};

export const CharityListSearchable = () => {
  const currentAuction = useCurrentAuction();

  const toast = useToast();

  const fetchCharities = async (
    query: string,
    start?: number,
    end?: number
  ): Promise<{ charities: any[] | null; count: number | null }> => {
    let { data, count } = await supabase
      .from("AllCharities")
      .select(
        "Fundraisername, CauseId, MissionStatement, JGURL, LogoDownloadUrl",
        {
          count: "exact",
        }
      )
      .textSearch("title_description", `'${query}'`)
      .range(start || 0, end || 10);

    return { charities: data, count };
  };

  const fetchTopDonatedCharities = async (
    query: string,
    start?: number,
    end?: number
  ): Promise<{ charities: any[] | null; count: number | null }> => {
    let { data, count } = await supabase
      .from("top_donated_charities")
      .select(
        "Fundraisername, CauseId, MissionStatement, JGURL, LogoDownloadUrl",
        {
          count: "exact",
        }
      )
      .range(start || 0, end || 10);

    console.log({ data, count });

    return { charities: data, count };
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const [selectedCharity, setSelectedCharity] = useState<CharityObject>();
  const [bidValue, setBidValue] = useState<string>("");
  const [donorName, setDonorname] = useLocalStorage("donorName", "Anon");
  const { library } = useEthers();

  const [_, cancel] = useDebounce(() => setDebouncedValue(searchTerm), 1000, [
    searchTerm,
  ]);

  const {
    data,
    isLoading,
    isError,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ["charities", debouncedValue],
    queryFn: ({ pageParam = 0 }) => {
      if (debouncedValue === "") {
        return fetchTopDonatedCharities(debouncedValue, pageParam, 50);
      } else {
        return fetchCharities(debouncedValue, pageParam, 50);
      }
    },
    onError: (error) => console.log(error),
    getNextPageParam: (lastPage, allPages) => {
      const pageSize = 50;
      const totalCharities =
        allPages.flatMap((page) => page.charities).length || 0;
      return Math.floor(totalCharities / pageSize);
    },
  });

  const charities = data?.pages.flatMap((page) => page.charities) || [];
  const charityCount = data?.pages.flatMap((page) => page.count)[0] || 0;

  const charityModalDisclosure = useDisclosure();

  const handleOpenBidModal = (charity: CharityObject) => {
    charityModalDisclosure.onOpen();
    setSelectedCharity(charity);
  };

  /** Bidding logic */

  const minBidIncPercentage = useAuctionMinBidIncPercentage();
  const minBid = currentAuction
    ? computeMinimumNextBid(currentAuction.amount, minBidIncPercentage)
    : EthersBN.from(0);

  const teddyAuctionHouseContract = new TeddyAuctionHouseFactory().attach(
    config.addresses.teddyAuctionHouseProxy
  );

  const { send: placeBid, state: placeBidState } = useContractFunction(
    //@ts-ignore
    teddyAuctionHouseContract,
    AuctionHouseContractFunction.createBid
  );

  const renderToast = (txStatus: TransactionStatus) => {
    let status = txStatus.status;
    if (status === `Mining`) {
      toast({
        duration: 10000,
        isClosable: true,
        render: (props) => (
          <ToastNotification
            id={`success-write`}
            title="Sent"
            description="Transaction submitted"
            onClose={props.onClose}
            status="info"
          />
        ),
      });
    } else if (status === `Fail`) {
      toast({
        duration: 10000,
        isClosable: true,
        render: (props) => (
          <ToastNotification
            id={`error`}
            title="Something went wrong"
            description={txStatus.errorMessage}
            onClose={props.onClose}
            status="error"
          />
        ),
      });
    } else if (status === `Exception`) {
      if (placeBidState.errorMessage === "evm error: OutOfFund") {
        toast({
          duration: 10000,
          isClosable: true,
          render: (props) => (
            <ToastNotification
              id={`error`}
              title="Not enough GLMR in your wallet"
              description={`Looks like you don't have ${bidValue} GLMR in your wallet to make this bid.`}
              onClose={props.onClose}
              status="error"
            />
          ),
        });
      } else {
        toast({
          duration: 10000,
          isClosable: true,
          render: (props) => (
            <ToastNotification
              id={`error`}
              title="Something went wrong"
              description={txStatus.errorMessage}
              onClose={props.onClose}
              status="error"
            />
          ),
        });
      }
    } else if (status === `Success`) {
      toast({
        duration: 10000,
        isClosable: true,
        render: (props) => (
          <ToastNotification
            id={`success-bid`}
            title="Success"
            description="Bid submitted"
            onClose={props.onClose}
            status="success"
          />
        ),
      });
    }
  };

  useEffect(() => {
    if (placeBidState.status === "Exception") {
      renderToast(placeBidState);
    }
  }, [placeBidState, toast]);

  const isPlaceBidDisabled = placeBidState.status === "Mining";

  console.log(currentAuction?.amount);

  const placeBidHandler = async (bid: BidObject) => {
    if (currentAuction) {
      if (currentBid(bid.value.toString()).lt(minBid)) {
        toast({
          title: `Insufficient bid amount 🤏`,
          description: `Please place a bid higher than or equal to the minimum bid amount of
            ${minBidEth(minBid)} GLMR`,
          variant: `left-accent`,
          position: `top-right`,
          styleConfig: {
            Alert: {
              container: {
                baseStye: {
                  bg: `white !important`,
                },
              },
            },
          },
          status: `error`,
          duration: 30000,
          isClosable: true,
          containerStyle: {
            maxWidth: `300px`,
          },
        });
        setBidValue(minBidEth(minBid));
        return;
      }

      const value = utils.parseEther(bid.value.toString());

      const contract = connectContractToSigner(
        teddyAuctionHouseContract,
        undefined,
        //@ts-ignore
        library
      );

      let charityNameWithId = `${bid.charityId}_${bid.charityName}`;

      const unsignedTx = await contract.populateTransaction.createBid(
        currentAuction.teddyId,
        charityNameWithId,
        bid.donorName || "Anon",
        {
          value,
          // gasLimit: gasLimit.add(10_000), // A 10,000 gas pad is used to avoid 'Out of gas' errors
        }
      );

      try {
        console.log("placing bid...");
        await placeBid(
          currentAuction.teddyId,
          charityNameWithId,
          bid.donorName || "Anon",
          {
            value,
            gasLimit: unsignedTx.gasLimit?.add(10_000),
            // gasLimit: gasLimit.add(10_000), // A 10,000 gas pad is used to avoid 'Out of gas' errors
          }
        );
      } catch (e: any) {}
    }
  };

  /** End bidding logic */

  return (
    <Container maxWidth="7xl" pt={[20, 20, 40]} pb={40}>
      <Box>
        <Heading textAlign="center" mb={12}>
          Charities
        </Heading>
      </Box>
      <Box maxWidth="md" margin="0 auto">
        <CharitySearch
          searchTerm={searchTerm}
          onChange={(search) => setSearchTerm(search)}
        />
      </Box>
      {charities && charityCount && charityCount > 0 ? (
        <Text pt={12} mb={2}>
          About{" "}
          <Text as="span" fontWeight="bold">
            {charityCount} charities
          </Text>{" "}
          for{" "}
          <Text as="span" fontWeight="bold">
            {searchTerm}
          </Text>
        </Text>
      ) : null}
      {debouncedValue === "" && (
        <Heading as="h2" mb={4}>
          Featured charities
        </Heading>
      )}
      {isError && <Text>{JSON.stringify(error)}</Text>}
      <Grid gap={6} gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}>
        {charities &&
          charities.length > 0 &&
          charities.map((charity, id) => {
            if (!charity) {
              return null;
            }
            return (
              <Box
                key={id}
                sx={{
                  py: 4,
                  border: `2px solid`,
                  borderColor: `#1a1730`,
                  borderRadius: "md",
                  maxWidth: `400px`,
                  boxShadow: `1px 1px 0px #1a1730,
                2px 2px 0px #1a1730,
                3px 3px 0px #1a1730,
                4px 4px 0px #1a1730,
                5px 5px 0px #1a1730,
                6px 6px 0px #1a1730`,
                }}
              >
                <Grid alignItems="center" gap={1}>
                  <VStack alignItems="flex-start" pt={2} px={4}>
                    <Grid
                      minHeight="100px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gridTemplate={"min-content/2fr 8fr"}
                      gap={4}
                    >
                      {charity?.LogoDownloadUrl && (
                        <Image
                          src={charity.LogoDownloadUrl}
                          maxWidth="100px"
                          maxHeight="100px"
                          alt={charity.Fundraisername}
                          fallbackSrc="https://via.placeholder.com/150"
                        />
                      )}
                      <Heading as="h3" fontSize="xl" textAlign="left">
                        {charity.Fundraisername}
                      </Heading>
                    </Grid>

                    <Box
                      height="150px"
                      overflow="scroll"
                      wordBreak="break-word"
                    >
                      <Text fontSize="sm">{charity.MissionStatement}</Text>
                    </Box>
                    <HStack>
                      <Button
                        variant="brand"
                        onClick={() =>
                          handleOpenBidModal({
                            charityName: charity.Fundraisername,
                            charityId: charity.CauseId,
                            charityDescription: charity.MissionStatement,
                            charityLogo: charity.LogoDownloadUrl,
                            charityUrl: charity.JGURL,
                          })
                        }
                      >
                        Bid to Support
                      </Button>
                      <Button
                        as="a"
                        href={charity.JGURL}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        Visit Website
                      </Button>
                    </HStack>
                  </VStack>
                </Grid>
              </Box>
            );
          })}
      </Grid>
      <Flex justifyContent="center" width="100%">
        <Button isLoading={isFetching} mt={8} onClick={() => fetchNextPage()}>
          Load more
        </Button>
      </Flex>
      {selectedCharity && (
        <CharityBidModal
          isOpen={charityModalDisclosure.isOpen}
          onClose={charityModalDisclosure.onClose}
          charity={selectedCharity}
          teddyId={currentAuction?.teddyId}
          placeBidState={placeBidState}
          bidValue={bidValue}
          currentHighestBid={currentAuction?.amount}
          minBid={Number(minBidEth(minBid))}
          onPlaceBid={(bid) => {
            placeBidHandler(bid);
          }}
          isPlaceBidDisabled={isPlaceBidDisabled}
          donorName={donorName || ""}
          onChangeBidValue={(value) => setBidValue(value.toString())}
          onChangeDonorName={(value) => setDonorname(value)}
        />
      )}
    </Container>
  );
};

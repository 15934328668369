import { ImageData as data, getTeddyData } from "@luckyfriday/assets";
import { buildSVG } from "@luckyfriday/sdk";
import { BigNumber as EthersBN } from "ethers";
import { ITeddySeed, useTeddySeed } from "../../wrappers/teddyToken";
import Teddy from "../Teddy";
import { Link } from "react-router-dom";
import classes from "./StandaloneTeddy.module.css";
import { useDispatch } from "react-redux";
import { setOnDisplayAuctionTeddyId } from "../../state/slices/onDisplayAuction";
import teddyClasses from "../Teddy/Teddy.module.css";

interface StandaloneTeddyProps {
  teddyId: EthersBN;
}
interface StandaloneCircularTeddyProps {
  teddyId: EthersBN;
  border?: boolean;
}

interface StandaloneTeddyWithSeedProps {
  teddyId: EthersBN;
  onLoadSeed?: (seed: ITeddySeed) => void;
  shouldLinkToProfile: boolean;
}

export const getTeddy = (teddyId: string | EthersBN, seed: ITeddySeed) => {
  const id = teddyId.toString();
  const name = `Teddy ${id}`;
  const description = `Teddy ${id} is a member of the Teddy DAO`;
  const { parts, background } = getTeddyData(seed);
  const image = `data:image/svg+xml;base64,${btoa(
    buildSVG(parts, data.palette, background)
  )}`;

  return {
    name,
    description,
    image,
  };
};

const StandaloneTeddy: React.FC<StandaloneTeddyProps> = (
  props: StandaloneTeddyProps
) => {
  const { teddyId } = props;
  const seed = useTeddySeed(teddyId);

  const teddy = seed && getTeddy(teddyId, seed);

  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(setOnDisplayAuctionTeddyId(teddyId.toNumber()));
  };

  return (
    <Link
      to={"/teddy/" + teddyId.toString()}
      className={classes.clickableTeddy}
      onClick={onClickHandler}
    >
      <Teddy
        imgPath={teddy ? teddy.image : ""}
        alt={teddy ? teddy.description : "Teddy"}
      />
    </Link>
  );
};

export const StandaloneTeddyCircular: React.FC<StandaloneCircularTeddyProps> = (
  props: StandaloneCircularTeddyProps
) => {
  const { teddyId, border } = props;
  const seed = useTeddySeed(teddyId);
  const teddy = seed && getTeddy(teddyId, seed);

  const dispatch = useDispatch();
  const onClickHandler = () => {
    dispatch(setOnDisplayAuctionTeddyId(teddyId.toNumber()));
  };

  if (!seed || !teddyId) return <Teddy imgPath="" alt="Teddy" />;

  return (
    <Link
      to={"/teddy/" + teddyId.toString()}
      className={classes.clickableTeddy}
      onClick={onClickHandler}
    >
      <Teddy
        imgPath={teddy ? teddy.image : ""}
        alt={teddy ? teddy.description : "Teddy"}
        wrapperClassName={teddyClasses.circularTeddyWrapper}
        className={border ? teddyClasses.circleWithBorder : teddyClasses.circular}
      />
    </Link>
  );
};

export const StandaloneTeddyRoundedCorners: React.FC<StandaloneTeddyProps> = (
  props: StandaloneTeddyProps
) => {
  const { teddyId } = props;
  const seed = useTeddySeed(teddyId);
  const teddy = seed && getTeddy(teddyId, seed);

  const dispatch = useDispatch();
  const onClickHandler = () => {
    dispatch(setOnDisplayAuctionTeddyId(teddyId.toNumber()));
  };

  return (
    <Link
      to={"/teddy/" + teddyId.toString()}
      className={classes.clickableTeddy}
      onClick={onClickHandler}
    >
      <Teddy
        imgPath={teddy ? teddy.image : ""}
        alt={teddy ? teddy.description : "Teddy"}
        className={teddyClasses.rounded}
      />
    </Link>
  );
};

export const StandaloneTeddyWithSeed: React.FC<StandaloneTeddyWithSeedProps> = (
  props: StandaloneTeddyWithSeedProps
) => {
  const { teddyId, onLoadSeed, shouldLinkToProfile } = props;

  const dispatch = useDispatch();
  const seed = useTeddySeed(teddyId);
  const seedIsInvalid = Object.values(seed || {}).every((v) => v === 0);

  if (!seed || seedIsInvalid || !teddyId || !onLoadSeed) {
    return <Teddy imgPath="" alt="Teddy" />;
  }


  onLoadSeed(seed);

  const onClickHandler = () => {
    dispatch(setOnDisplayAuctionTeddyId(teddyId.toNumber()));
  };

  const { image, description } = getTeddy(teddyId, seed);

  const teddy = <Teddy imgPath={image} alt={description} />;

  const teddyWithLink = (
    <Link
      to={"/teddy/" + teddyId.toString()}
      className={classes.clickableTeddy}
      onClick={onClickHandler}
    >
      {teddy}
    </Link>
  );
  return shouldLinkToProfile ? teddyWithLink : teddy;
};

export default StandaloneTeddy;

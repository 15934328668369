import { Col } from "react-bootstrap";
import { ReactChildren } from "../../utils/types";
import classes from "./AuctionTitleAndNavWrapper.module.css";

const AuctionTitleAndNavWrapper: React.FC<{
  children: ReactChildren;
}> = (props) => {
  return (
    <Col lg={12} className={classes.auctionTitleAndNavContainer}>
      {props.children}
    </Col>
  );
};
export default AuctionTitleAndNavWrapper;

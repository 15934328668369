import one from '../assets/numbers/1.png'
import two from "../assets/numbers/2.png";
import three from "../assets/numbers/3.png";
import four from "../assets/numbers/4.png";
import five from "../assets/numbers/5.png";
import six from "../assets/numbers/6.png";
import seven from "../assets/numbers/7.png";
import eight from "../assets/numbers/8.png";
import nine from "../assets/numbers/9.png";
import zero from '../assets/numbers/0.png'

export const convertNumberIntoPixelPng = (value: number) => {
  const valueAsString = value.toString()

  const numbersAsArrayOfString = valueAsString === "0" ? ["0"] : valueAsString.split("")

  let pixelArray = numbersAsArrayOfString.map((num) => {
    switch (num) {
      case "0":
        return {
          img: zero,
          value: num
        }
      case "1":
        return  {
          img: one,
          value: num
        }
      case "2":
        return  {
          img: two,
          value: num
        };
      case "3":
        return {
          img: three,
          value: num,
        };;
      case "4":
        return {
          img: four,
          value: num,
        };;
      case "5":
        return {
          img: five,
          value: num,
        };;
      case "6":
        return {
          img: six,
          value: num,
        };;
      case "7":
        return {
          img: seven,
          value: num,
        };;
      case "8":
        return {
          img: eight,
          value: num,
        };;
      case "9":
        return {
          img: nine,
          value: num,
        };;
      default:
        return null;
    }
  });

  return pixelArray
}

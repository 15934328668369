import { useQuery } from "@apollo/client";
import React from "react";
import { Image } from "react-bootstrap";
import _LinkIcon from "../../assets/icons/Link.svg";
import { auctionQuery } from "../../wrappers/subgraph";
import _HeartIcon from "../../assets/icons/Heart.svg";
import classes from "./TeddyInfoRowHolder.module.css";

import config from "../../config";
import { buildEtherscanAddressLink } from "../../utils/etherscan";
import ShortAddress from "../ShortAddress";

import { useAppSelector } from "../../hooks";
import { Trans } from "@lingui/macro";

interface TeddyInfoRowHolderProps {
  teddyId: number;
}

const TeddyInfoRowHolder: React.FC<TeddyInfoRowHolderProps> = (props) => {
  const { teddyId } = props;
  const isCool = useAppSelector((state) => state.application.isCoolBackground);
  const { loading, error, data } = useQuery(auctionQuery(teddyId));

  const winner = data && data.auction?.bidder?.id;

  if (loading || !winner) {
    return (
      <div className={classes.teddyHolderInfoContainer}>
        <span className={classes.teddyHolderLoading}>
          <Trans>Loading...</Trans>
        </span>
      </div>
    );
  } else if (error) {
    return (
      <div>
        <Trans>Failed to fetch Teddy info</Trans>
      </div>
    );
  }

  const etherscanURL = buildEtherscanAddressLink(winner);
  const shortAddressComponent = <ShortAddress address={winner} />;

  return (
    <div className={classes.teddyHolderInfoContainer}>
      <span>
        <Image src={_HeartIcon} className={classes.heartIcon} />
      </span>
      <span>
        <Trans>Winner</Trans>
      </span>
      <span>
        <a
          className={
            isCool
              ? classes.teddyHolderEtherscanLinkCool
              : classes.teddyHolderEtherscanLinkWarm
          }
          href={etherscanURL}
          target={"_blank"}
          rel="noreferrer"
        >
          {winner.toLowerCase() ===
          config.addresses.teddyAuctionHouseProxy.toLowerCase() ? (
            <Trans>Teddy Auction House</Trans>
          ) : (
            shortAddressComponent
          )}
        </a>
      </span>
      <span className={classes.linkIconSpan}>
        <Image src={_LinkIcon} className={classes.linkIcon} />
      </span>
    </div>
  );
};

export default TeddyInfoRowHolder;

import {
  Auction,
  AuctionHouseContractFunction,
} from "../../wrappers/teddyAuction";
import {
  useEthers,
  useContractFunction,
  TransactionStatus,
} from "@usedapp/core";
import { connectContractToSigner } from "@usedapp/core/dist/cjs/src/hooks";
import { useAppSelector } from "../../hooks";
import React, { useEffect, useState, useCallback } from "react";
import { utils, BigNumber as EthersBN } from "ethers";
import BigNumber from "bignumber.js";
import { Col } from "react-bootstrap";
import { useAuctionMinBidIncPercentage } from "../../wrappers/teddyAuction";
import { useAppDispatch } from "../../hooks";
import { AlertModal, setAlertModal } from "../../state/slices/application";
import { TeddyAuctionHouseFactory } from "@luckyfriday/sdk";
import config, { CHAIN_ID } from "../../config";
import { WalletConnectModal } from "../WalletConnectModal";
import SettleManuallyBtn from "../SettleManuallyBtn";
import { Trans } from "@lingui/macro";

import {
  BidObject,
  BidSelectorModal,
  CharityObject,
} from "../BidSelectorModal";
import { useDisclosure } from "@chakra-ui/hooks";
import { Input } from "@chakra-ui/input";
import { HStack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { Button } from "@chakra-ui/react";
import { useLocalStorage } from "react-use";

import { ToastNotification } from "../ToastNotification";
import { SuccessBidModal } from "../SuccesBidModal/SuccessBidModal";
import { formatEther, parseEther } from "ethers/lib/utils";

const computeMinimumNextBid = (
  currentBid: EthersBN,
  minBidIncPercentage: EthersBN | undefined
): EthersBN => {
  if (!minBidIncPercentage) {
    return EthersBN.from(0);
  }

  const percentage = Number(minBidIncPercentage.toString()) / 100 + 1;
  const value = formatEther(currentBid);
  const nextBid = percentage * Number(value);

  const nextBidClean = nextBid > 0.01 ? nextBid : 0.01;

  return parseEther(nextBidClean.toString());
};

export const minBidEth = (minBid: EthersBN): string => {
  if (minBid.isZero()) {
    return "0.01";
  }

  const eth = utils.formatEther(minBid);
  return new BigNumber(eth).toFixed(2, BigNumber.ROUND_CEIL);
};

const currentBid = (value: string) => {
  if (value !== "") {
    return utils.parseEther(value);
  } else {
    return EthersBN.from(0);
  }
};

const Bid: React.FC<{
  auction: Auction;
  auctionEnded: boolean;
  onBidSuccess: () => void;
}> = (props) => {
  const activeAccount = useAppSelector((state) => state.account.activeAccount);
  const { library, chainId, account, switchNetwork } = useEthers();
  let { auction, auctionEnded } = props;
  const teddyAuctionHouseContract = new TeddyAuctionHouseFactory().attach(
    config.addresses.teddyAuctionHouseProxy
  );

  const [amlScore, setAmlScore] = useLocalStorage("amlScore");
  const currentAmlScore = amlScore as any;
  const isAmlFail = currentAmlScore && currentAmlScore >= 10;

  const toast = useToast();

  //eslint-disable-next-line
  const [bidButtonContent, setBidButtonContent] = useState({
    loading: false,
    content: auctionEnded ? <Trans>Settle</Trans> : <Trans>Start bid</Trans>,
  });

  const [showConnectModal, setShowConnectModal] = useState(false);

  const hideModalHandler = () => {
    setShowConnectModal(false);
  };

  const dispatch = useAppDispatch();
  const setModal = useCallback(
    (modal: AlertModal) => dispatch(setAlertModal(modal)),
    [dispatch]
  );
  const [bidValue, setBidValue] = useState<string>("");
  const [donorName, setDonorname] = useLocalStorage("donorName", "Anon");
  const [charity, setCharity] = useState<CharityObject | null>(null);
  // const [donorName, setDonorname] = useState<string>("Anon");
  console.log({ charity });
  const bidModalDisclosure = useDisclosure();
  const successBidModalDisclosure = useDisclosure();

  const minBidIncPercentage = useAuctionMinBidIncPercentage();
  const minBid = computeMinimumNextBid(
    auction && auction.amount,
    minBidIncPercentage
  );

  const { send: placeBid, state: placeBidState } = useContractFunction(
    //@ts-ignore
    teddyAuctionHouseContract,
    AuctionHouseContractFunction.createBid
  );
  const { send: settleAuction, state: settleAuctionState } =
    useContractFunction(
      //@ts-ignore
      teddyAuctionHouseContract,
      AuctionHouseContractFunction.settleCurrentAndCreateNewAuction
    );

  const placeBidHandler = async (bid: BidObject) => {
    console.log("placing bid");
    if (currentBid(bid.value.toString()).lt(minBid)) {
      toast({
        title: `Insufficient bid amount 🤏`,
        description: `Please place a bid higher than or equal to the minimum bid amount of
            ${minBidEth(minBid)} GLMR`,
        variant: `left-accent`,
        position: `top-right`,
        styleConfig: {
          Alert: {
            container: {
              baseStye: {
                bg: `white !important`,
              },
            },
          },
        },
        status: `error`,
        duration: 30000,
        isClosable: true,
        containerStyle: {
          maxWidth: `300px`,
        },
      });
      setBidValue(minBidEth(minBid));
      return;
    }

    const value = utils.parseEther(bid.value.toString());

    const contract = connectContractToSigner(
      teddyAuctionHouseContract,
      undefined,
      //@ts-ignore
      library
    );

    let charityNameWithId = `${bid.charityId}_${bid.charityName}`;

    const unsignedTx = await contract.populateTransaction.createBid(
      auction.teddyId,
      charityNameWithId,
      bid.donorName || "Anon",
      {
        value,
        // gasLimit: gasLimit.add(10_000), // A 10,000 gas pad is used to avoid 'Out of gas' errors
      }
    );

    try {
      await placeBid(
        auction.teddyId,
        charityNameWithId,
        bid.donorName || "Anon",
        {
          value,
          gasLimit: unsignedTx.gasLimit?.add(10_000),
          // gasLimit: gasLimit.add(10_000), // A 10,000 gas pad is used to avoid 'Out of gas' errors
        }
      );
    } catch (e) {
      console.log(e);
    }

    bidModalDisclosure.onClose();
  };

  const settleAuctionHandler = () => {
    settleAuction();
  };

  const renderToast = (txStatus: TransactionStatus) => {
    let status = txStatus.status;
    if (status === `Mining`) {
      bidModalDisclosure.onClose();
      toast({
        duration: 10000,
        isClosable: true,
        render: (props) => (
          <ToastNotification
            id={`success-write`}
            title="Sent"
            description="Transaction submitted"
            onClose={props.onClose}
            status="info"
          />
        ),
      });
    } else if (status === `Fail`) {
      toast({
        duration: 10000,
        isClosable: true,
        render: (props) => (
          <ToastNotification
            id={`error`}
            title="Something went wrong`"
            description={txStatus.errorMessage}
            onClose={props.onClose}
            status="error"
          />
        ),
      });
    } else if (status === `Exception`) {
      toast({
        duration: 10000,
        isClosable: true,
        render: (props) => (
          <ToastNotification
            id={`error`}
            title="Something went wrong`"
            description={txStatus.errorMessage}
            onClose={props.onClose}
            status="error"
          />
        ),
      });
    } else if (status === `Success`) {
      props.onBidSuccess && props.onBidSuccess();
      successBidModalDisclosure.onOpen();
      toast({
        duration: 10000,
        isClosable: true,
        render: (props) => (
          <ToastNotification
            id={`success-bid`}
            title="Success"
            description="Bid submitted"
            onClose={props.onClose}
            status="success"
          />
        ),
      });
    }
  };

  // placing bid transaction state hook
  useEffect(() => {
    console.log(placeBidState.status);
    if (!auctionEnded) {
      renderToast(placeBidState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeBidState, auctionEnded]);

  // settle auction transaction state hook
  useEffect(() => {
    switch (auctionEnded && settleAuctionState.status) {
      case "None":
        setBidButtonContent({
          loading: false,
          content: <Trans>Settle Auction</Trans>,
        });
        break;
      case "Mining":
        setBidButtonContent({ loading: true, content: <></> });
        break;
      case "Success":
        setModal({
          title: <Trans>Success</Trans>,
          message: <Trans>Settled auction successfully!</Trans>,
          show: true,
        });
        setBidButtonContent({
          loading: false,
          content: <Trans>Settle Auction</Trans>,
        });
        break;
      case "Fail":
        setModal({
          title: <Trans>Transaction Failed</Trans>,
          message: settleAuctionState?.errorMessage || (
            <Trans>Please try again.</Trans>
          ),
          show: true,
        });
        setBidButtonContent({
          loading: false,
          content: <Trans>Settle Auction</Trans>,
        });
        break;
      case "Exception":
        setModal({
          title: <Trans>Error</Trans>,
          message: settleAuctionState?.errorMessage || (
            <Trans>Please try again.</Trans>
          ),
          show: true,
        });
        setBidButtonContent({
          loading: false,
          content: <Trans>Settle Auction</Trans>,
        });
        break;
    }
  }, [settleAuctionState, auctionEnded, setModal]);

  if (!auction) return null;

  const isDisabled =
    placeBidState.status === "Mining" ||
    settleAuctionState.status === "Mining" ||
    !activeAccount;

  const isWalletConnected = activeAccount !== undefined;

  const handlePlaceBidModal = () => {
    if (bidValue === "") {
      setBidValue(minBidEth(minBid));
    }
    bidModalDisclosure.onOpen();
  };

  const isWrongNetwork = Number(CHAIN_ID) !== chainId;

  const renderBidButton = () => {
    if (account) {
      if (isWrongNetwork) {
        return (
          <Button
            colorScheme="brand"
            onClick={async () => {
              await switchNetwork(CHAIN_ID);
            }}
            style={{
              width: `200px`,
            }}
          >
            Switch network
          </Button>
        );
      } else if (isAmlFail) {
        return (
          <Button colorScheme="brand" disabled={true} width="100%">
            Cannot bid due to AML compliance. Please contact us on Discord.
          </Button>
        );
      } else {
        return (
          <Button
            colorScheme="brand"
            onClick={handlePlaceBidModal}
            disabled={isDisabled}
            isLoading={placeBidState.status === "Mining"}
            style={{
              width: `200px`,
            }}
          >
            Place bid
          </Button>
        );
      }
    } else {
      return (
        <Button
          colorScheme="brand"
          onClick={() => setShowConnectModal(true)}
          style={{
            width: `200px`,
          }}
        >
          Connect Wallet
        </Button>
      );
    }
  };

  return (
    <>
      {showConnectModal && activeAccount === undefined && (
        <WalletConnectModal
          onClose={hideModalHandler}
          isOpen={showConnectModal}
        />
      )}
      <HStack>
        {!auctionEnded && (
          <>
            <Input
              type="number"
              border="2px solid"
              borderColor="brand.500"
              _hover={{
                borderColor: "brand.500",
              }}
              onChange={(e) => setBidValue(e.target.value)}
              value={bidValue}
              placeholder={`${minBidEth(minBid).toString()} GLMR or more`}
              display={isAmlFail ? "none" : "block"}
            />
          </>
        )}
        {!auctionEnded ? (
          renderBidButton()
        ) : (
          <>
            {/* Only show force settle button if wallet connected */}
            {isWalletConnected && (
              <Col lg={12}>
                <SettleManuallyBtn
                  settleAuctionHandler={settleAuctionHandler}
                  auction={auction}
                />
              </Col>
            )}
          </>
        )}
      </HStack>
      {auction?.teddyId && (
        <SuccessBidModal
          isOpen={successBidModalDisclosure.isOpen}
          onClose={successBidModalDisclosure.onClose}
          bidValue={bidValue}
          teddyId={auction.teddyId.toString()}
          charityName={charity?.charityName}
        />
      )}
      {bidModalDisclosure.isOpen && (
        <BidSelectorModal
          isOpen={bidModalDisclosure.isOpen}
          onClose={bidModalDisclosure.onClose}
          onChangeBidValue={(value) => setBidValue(value.toString())}
          onChangeDonorName={(value) => setDonorname(value)}
          onPlaceBid={(bid) => {
            placeBidHandler(bid);
          }}
          onSelectCharity={(charity) => setCharity(charity)}
          donorName={donorName}
          bidValue={bidValue}
          minBid={Number(minBidEth(minBid))}
        />
      )}
    </>
  );
};
export default Bid;

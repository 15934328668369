import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "./tailwind.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChainId, DAppProvider, Moonbeam } from "@usedapp/core";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import {
  ChakraProvider,
  ColorModeScript,
  LightMode,
  useForceUpdate,
} from "@chakra-ui/react";
import account from "./state/slices/account";
import application from "./state/slices/application";
import logs from "./state/slices/logs";
import auction, {
  reduxSafeAuction,
  reduxSafeNewAuction,
  reduxSafeBid,
  setActiveAuction,
  setAuctionExtended,
  setAuctionSettled,
  setFullAuction,
  setAuctionOfficialEndTimeChanged,
} from "./state/slices/auction";
import onDisplayAuction, {
  setLastAuctionTeddyId,
  setOnDisplayAuctionTeddyId,
} from "./state/slices/onDisplayAuction";
import { ApolloProvider, useQuery } from "@apollo/client";
import { clientFactory, latestAuctionsQuery } from "./wrappers/subgraph";
import { useEffect } from "react";
import pastAuctions, { addPastAuctions } from "./state/slices/pastAuctions";
import LogsUpdater from "./state/updaters/logs";
import config, { CHAIN_ID } from "./config";
import { WebSocketProvider } from "@ethersproject/providers";
import { BigNumber, BigNumberish, providers } from "ethers";
import { TeddyAuctionHouseFactory } from "@luckyfriday/sdk";
import dotenv from "dotenv";
import { useAppDispatch, useAppSelector } from "./hooks";
import { appendBid } from "./state/slices/auction";
import { ConnectedRouter, connectRouter } from "connected-react-router";
import { createBrowserHistory, History } from "history";
import {
  applyMiddleware,
  createStore,
  combineReducers,
  PreloadedState,
} from "redux";
import { routerMiddleware } from "connected-react-router";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { teddyPath } from "./utils/history";
import { push } from "connected-react-router";
import { LanguageProvider } from "./i18n/LanguageProvider";
import { theme } from "./theme";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

dotenv.config();

export const history = createBrowserHistory();

const queryClient = new QueryClient();

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    account,
    application,
    auction,
    logs,
    pastAuctions,
    onDisplayAuction,
  });

export default function configureStore(preloadedState: PreloadedState<any>) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history) // for dispatching history actions
        // ... other middlewares ...
      )
    )
  );

  return store;
}

const store = configureStore({});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const supportedChainURLs = {
  [ChainId.MoonbaseAlpha]: "https://moonbase-alpha.public.blastapi.io",
  [ChainId.Moonbeam]: "https://moonbeam.public.blastapi.io",
};

// prettier-ignore
const useDappConfig = {
  readOnlyChainId: CHAIN_ID,
  readOnlyUrls: {
    [CHAIN_ID]: supportedChainURLs[CHAIN_ID],
  },
};

const client = clientFactory(config.app.subgraphApiUri);

const Updaters = () => {
  return (
    <>
      <LogsUpdater />
    </>
  );
};

const BLOCKS_PER_DAY = 6_500;

const ChainSubscriber: React.FC = () => {
  const dispatch = useAppDispatch();

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      forceUpdate();
    }, 1000 * 6); // in milliseconds
    return () => clearInterval(intervalId);
  }, [forceUpdate]);

  const loadState = async () => {
    const network = providers.getNetwork(Moonbeam.chainId);

    const wsProvider = new WebSocketProvider(config.app.wsRpcUri, network);
    const teddyAuctionHouseContract = TeddyAuctionHouseFactory.connect(
      config.addresses.teddyAuctionHouseProxy,
      wsProvider
    );

    const bidFilter = teddyAuctionHouseContract.filters.AuctionBid(
      null,
      null,
      null,
      null
    );
    const extendedFilter = teddyAuctionHouseContract.filters.AuctionExtended(
      null,
      null
    );
    const createdFilter = teddyAuctionHouseContract.filters.AuctionCreated(
      null,
      null,
      null
    );
    const settledFilter = teddyAuctionHouseContract.filters.AuctionSettled(
      null,
      null,
      null
    );

    const officialEndTimeFilter =
      teddyAuctionHouseContract.filters.AuctionOfficialEndTimeUpdated(null);
    const processBidFilter = async (
      teddyId: BigNumberish,
      sender: string,
      value: BigNumberish,
      extended: boolean,
      charityName: string,
      donorName: string,
      event: any
    ) => {
      const timestamp = (await event.getBlock()).timestamp;
      const transactionHash = event.transactionHash;

      dispatch(
        appendBid(
          reduxSafeBid({
            teddyId,
            sender,
            value,
            extended,
            transactionHash,
            timestamp,
            donorName,
            charityName,
          })
        )
      );
    };
    const processAuctionCreated = (
      teddyId: BigNumberish,
      startTime: BigNumberish,
      endTime: BigNumberish
    ) => {
      dispatch(
        setActiveAuction(
          reduxSafeNewAuction({ teddyId, startTime, endTime, settled: false })
        )
      );
      const teddyIdNumber = BigNumber.from(teddyId).toNumber();
      dispatch(push(teddyPath(teddyIdNumber)));
      dispatch(setOnDisplayAuctionTeddyId(teddyIdNumber));
      dispatch(setLastAuctionTeddyId(teddyIdNumber));
    };
    const processAuctionExtended = (
      teddyId: BigNumberish,
      endTime: BigNumberish
    ) => {
      dispatch(setAuctionExtended({ teddyId, endTime }));
    };
    const processAuctionSettled = (
      teddyId: BigNumberish,
      winner: string,
      amount: BigNumberish
    ) => {
      dispatch(setAuctionSettled({ teddyId, amount, winner }));
    };

    const processAuctionOfficialEndTimeUpdated = (endTime: BigNumberish) => {
      dispatch(setAuctionOfficialEndTimeChanged({ endTime }));
    };

    // Fetch the current auction
    const currentAuction = await teddyAuctionHouseContract.auction();
    dispatch(setFullAuction(reduxSafeAuction(currentAuction)));
    dispatch(setLastAuctionTeddyId(currentAuction.teddyId.toNumber()));

    // Fetch the previous 24hours of  bids
    const previousBids = await teddyAuctionHouseContract.queryFilter(
      bidFilter,
      0 - BLOCKS_PER_DAY
    );
    for (let event of previousBids) {
      if (event.args === undefined) return;
      processBidFilter(
        ...(event.args as [
          BigNumber,
          string,
          BigNumber,
          boolean,
          string,
          string
        ]),
        event
      );
    }

    teddyAuctionHouseContract.on(
      bidFilter,
      (teddyId, sender, value, extended, charityName, donorName, event) =>
        processBidFilter(
          teddyId,
          sender,
          value,
          extended,
          charityName,
          donorName,
          event
        )
    );
    teddyAuctionHouseContract.on(
      createdFilter,
      (teddyId, startTime, endTime) => {
        console.log({ teddyId, startTime, endTime });
        return processAuctionCreated(teddyId, startTime, endTime);
      }
    );
    teddyAuctionHouseContract.on(extendedFilter, (teddyId, endTime) =>
      processAuctionExtended(teddyId, endTime)
    );
    teddyAuctionHouseContract.on(settledFilter, (teddyId, winner, amount) =>
      processAuctionSettled(teddyId, winner, amount)
    );
    teddyAuctionHouseContract.on(officialEndTimeFilter, (officialEndTime) =>
      processAuctionOfficialEndTimeUpdated(officialEndTime)
    );
  };
  loadState();

  return <></>;
};

const PastAuctions: React.FC = () => {
  const latestAuctionId = useAppSelector(
    (state) => state.onDisplayAuction.lastAuctionTeddyId
  );
  const { data } = useQuery(latestAuctionsQuery());
  const dispatch = useAppDispatch();

  useEffect(() => {
    data && dispatch(addPastAuctions({ data }));
  }, [data, latestAuctionId, dispatch]);

  return <></>;
};

const container = document.getElementById("root");
//@ts-ignore
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ChainSubscriber />
      <React.StrictMode>
        <Web3ReactProvider
          getLibrary={
            (provider) => new Web3Provider(provider) // this will vary according to whether you use e.g. ethers or web3.js
          }
        >
          <QueryClientProvider client={queryClient}>
            <ApolloProvider client={client}>
              <PastAuctions />
              <DAppProvider config={useDappConfig}>
                <LanguageProvider>
                  <ChakraProvider theme={theme}>
                    <ColorModeScript
                      initialColorMode="system"
                      type="localStorage"
                    />
                    <LightMode>
                      <App />
                    </LightMode>
                  </ChakraProvider>
                </LanguageProvider>
                <Updaters />
              </DAppProvider>
            </ApolloProvider>
          </QueryClientProvider>
        </Web3ReactProvider>
      </React.StrictMode>
    </ConnectedRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

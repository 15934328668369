import React from 'react';
import { usePress } from "react-aria";
import { Button, HStack, Image } from '@chakra-ui/react';
import LeftArrow from '../../assets/icons/LeftArrow.png'
import RightArrow from "../../assets/icons/RightArrow.png";
import RightArrowClicked from "../../assets/icons/RightArrowClicked.png";
import LeftArrowClicked from "../../assets/icons/LeftArrowClicked.png";


const AuctionNavigation: React.FC<{
  isFirstAuction: boolean;
  isLastAuction: boolean;
  onPrevAuctionClick: () => void;
  onNextAuctionClick: () => void;
}> = props => {
  const { isFirstAuction, isLastAuction, onPrevAuctionClick, onNextAuctionClick } = props;

  const rightPress= usePress({
    onPressEnd: e => {
      onNextAuctionClick();
    }
  })

  const leftPress = usePress({
    onPressEnd: (e) => {
      onPrevAuctionClick();
    },
  });

  return (
    <HStack>
      <Button
        {...leftPress.pressProps}
        disabled={isFirstAuction}
        p={0}
        variant="brand"
      >
        {leftPress.isPressed ? (
          <Image width="24px" src={LeftArrowClicked} alt="left" />
        ) : (
          <Image width="24px" src={LeftArrow} alt="left" />
        )}{" "}
      </Button>
      <Button
        {...rightPress.pressProps}
        variant="brand"
        p={0}
        disabled={isLastAuction}
      >
        {rightPress.isPressed ? (
          <Image width="24px" src={RightArrowClicked} alt="right" />
        ) : (
          <Image width="24px" src={RightArrow} alt="right" />
        )}
      </Button>
    </HStack>
  );
};
export default AuctionNavigation;

/**
 * LanguageProvider.tsx is a modified version of https://github.com/Uniswap/interface/blob/main/src/lib/i18n.tsx
 */
import { SupportedLocale } from './locales';
import { initialLocale, useActiveLocale } from '../hooks/useActivateLocale';
import { dynamicActivate, TeddyI18nProvider } from './TeddyI18nProvider';
import { useCallback } from 'react';

dynamicActivate(initialLocale);

export function LanguageProvider({ children }: { children: JSX.Element }) {
  const locale = useActiveLocale();

  const onActivate = useCallback((locale: SupportedLocale) => {
    dynamicActivate(locale);
  }, []);

  return (
    <TeddyI18nProvider locale={locale} forceRenderAfterLocaleChange={true} onActivate={onActivate}>
      {children}
    </TeddyI18nProvider>
  );
}

import { Box, Container, Heading, Image, Text } from "@chakra-ui/react"
import { Moonbeam } from "@usedapp/core";
import { colors } from "../../theme";
import { navbarHeight } from "../../theme/constants";

import teddyBannerBridge from "../../assets/banners/teddy-bridge-banner.png";


const bridgeConfig = {
  integratorId: "squid-swap-widget",
  companyName: "Teddy DAO",
  style: {
    neutralContent: "#747379",
    baseContent: "#2E2C33",
    base100: "#F5F5F7",
    base200: "#F2F2F2",
    base300: "#DADADA",
    error: "#ED6A5E",
    warning: "#FFB155",
    success: "#2EAEB0",
    primary: colors.brand[500],
    secondary: "#070707",
    secondaryContent: "#FFFFFF",
    neutral: "#FFFFFF",
    roundedBtn: "8px",
    roundedCornerBtn: "8px",
    roundedBox: "1rem",
    roundedDropDown: "8px",
  },
  slippage: 1.5,
  infiniteApproval: false,
  enableExpress: true,
  apiUrl: "https://api.squidrouter.com",
  comingSoonChainIds: [],
  titles: {
    swap: "Swap",
    settings: "Settings",
    wallets: "Wallets",
    tokens: "Select Token",
    chains: "Select Chain",
    history: "History",
    transaction: "Transaction",
    allTokens: "Select Token",
    destination: "Destination address",
  },
  priceImpactWarnings: {
    warning: 3,
    critical: 5,
  },
  showOnRampLink: true,
  initialFromChainId: 1,
  initialToChainId: Moonbeam.chainId,
  defaultTokens: [
    {
      address: "0x2170ed0880ac9a755fd29b2688956bd959f933f8", // Token address for SUSHI
      chainId: 1, // Chain ID for Arbitrum
    },
    {
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE", // Token address for GLMR,
      chainId: Moonbeam.chainId, // Chain ID for Moonbeam
    },
  ],
};

export const BridgePage = () => {
  const config = JSON.stringify(bridgeConfig);
  const encodedConfig = encodeURIComponent(config);
  const widgetUrl = `https://widget.squidrouter.com/iframe?config=${encodedConfig}`;
  return (
    <Box pt={[12,12,12,navbarHeight]}>
      <Box
        sx={{
          display: `grid`,
          gridTemplateColumns: [`1fr`, `1fr`, `1fr 1fr`],
        }}
      >
        <Container
          maxWidth="3xl"
          sx={{
            display: `flex`,
            flexDirection: `column`,
            alignItems: `flex-end`,
            pt: [0, 0, 0, 12],
            pb: [12,12,12,0]
          }}
        >
          <Box
            sx={{
              position: `relative`,
            }}
          >
            <Image src={teddyBannerBridge} alt="stella swap" width="100%" />
            <Box
              sx={{
                // position: `absolute`,
                textAlign: `center`,
                top: 0,
                left: 0,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `flex-end`,
              }}
            >
              <Container maxWidth="md">
                <Heading textAlign="center">Bridge to Moonbeam</Heading>
                <Text fontSize="lg">
                  Effortlessly exchange your native tokens for Moonbeam's GLMR,
                  enabling you to participate in our Teddy auctions.
                </Text>
              </Container>
            </Box>
          </Box>
        </Container>
        <Container
          pb={24}
          sx={{
            display: `flex`,
            flexDirection: `column`,
            alignItems: `flex-start`,
            justifyContent: `center`,
          }}
        >
          <Box
            ml={-3}
            sx={{
              border: `2px solid`,
              borderColor: `#1a1730`,
              borderRadius: "md",
              boxShadow: `1px 1px 0px #1a1730,
                2px 2px 0px #1a1730,
                3px 3px 0px #1a1730,
                4px 4px 0px #1a1730,
                5px 5px 0px #1a1730,
                6px 6px 0px #1a1730`,
              height: `700px`,
            }}
          >
            <iframe
              title="squid_widget"
              width="420"
              height="700"
              src={widgetUrl}
            />
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
import {
  Badge,
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import presentIcon from "../../assets/teddy-icons/present-icon.png";
import { CgExternal } from "react-icons/cg";

interface CustomTeddyBadgeProps {
  id: number;
  message: string;
  link: string;
  sponsorName: string;
  logo?: string;
}

export const CustomTeddyBadge = ({
  id,
  message,
  link,
  sponsorName,
  logo,
}: CustomTeddyBadgeProps) => {
  return (
    <Box>
      <Box
        overflow="hidden"
        position="relative"
        height="400px"
        borderRight="8px solid"
        borderColor="orange.800"
        bg="accent.500"
        p={4}
        display="flex"
        flexDirection="column"
      >
        <Box position="relative" zIndex={1}>
          <Box>
            <Text
              fontSize="sm"
              fontWeight="bold"
              color="white"
              textTransform="uppercase"
              lineHeight={1}
            >
              Custom Teddy
            </Text>
            <Text>
              <Text
                lineHeight={1}
                color="orange.900"
                fontSize="4xl"
                fontWeight="bold"
                as="span"
              >
                {sponsorName}
              </Text>
            </Text>
            <Link
              aria-label="website"
              fontSize="xs"
              href={link}
              target="_blank"
              rel="noopener noreferrer nofollow"
              variant="ghost"
              color="white"
              p={0}
              textDecoration="underline"
            >
              {link}
            </Link>
          </Box>
        </Box>
        <Box
          position="absolute"
          left={4}
          bottom={4}
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
        >
          {logo && (
            <Image src={logo} alt={sponsorName} height="auto" width="150px" />
          )}
        </Box>
      </Box>
    </Box>
  );
};

import { useEffect } from "react";
import { withRouter } from "react-router-dom";

function ScrollToTop({ history } : { history: any}) {
  useEffect(() => {
    //@ts-ignore
    // eslint-disable-next-line
    if (typeof window !== undefined) {
      const unlisten = history.listen(() => {
        //@ts-ignore
        window.scrollTo(0, 0);
      });
      return () => {
        unlisten();
      };
    }
    // eslint-disable-next-line
  }, []);

  return null;
}

export default withRouter(ScrollToTop);

import {
  Modal,
  ModalContent,
  ModalBody,
  Button,
  ModalOverlay,
  Text,
  Box,
  HStack,
} from "@chakra-ui/react";
import { TwitterShareButton } from "react-share";
import Lottie, { LottieRef } from "lottie-react";
import { useRef } from "react";

import confetti from "../../assets/lottie/confetti-5.json";
import { convertUtcToLocalTime } from "../../utils/convertUtcToLocalTime";

type SuccessBidModalProps = {
  isOpen: boolean;
  onClose: () => void;
  bidValue: string;
  charityName: string | undefined;
  teddyId: string;
};

export const SuccessBidModal = ({
  isOpen,
  onClose,
  bidValue,
  teddyId,
  charityName,
}: SuccessBidModalProps) => {
  const ref: LottieRef = useRef(null);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="blackAlpha.900" />
        <ModalContent
          maxWidth="5xl"
          bg="transparent"
          boxShadow="none"
          zIndex={1}
          border="none"
        >
          <ModalBody
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            <Box position="absolute" zIndex={1} textAlign="center">
              <Text
                fontWeight="bold"
                fontSize={["4xl", "4xl"]}
                lineHeight={1}
                color="accent.500"
                textTransform="uppercase"
                mb={4}
              >
                Bid Succesful!
              </Text>
              <Text color="white" maxWidth="md">
                Make sure to check before 2PM UTC (
                {convertUtcToLocalTime("2023-06-05T14:00:00Z")}) if you are
                still the highest bidder to win today{`s`} Teddy.
              </Text>
              <HStack
                mt={8}
                justifyContent="center"
                flexDirection="row"
                spacing={2}
              >
                <Button onClick={onClose}>Continue</Button>
                <Box
                  sx={{
                    color: `white`,
                    height: `40px`,
                    bg: `twitter.500`,
                    borderRadius: 5,
                    px: 4,
                    fontWeight: `600`,
                  }}
                >
                  <Button
                    as={TwitterShareButton}
                    url="https://teddydao.org/auction"
                    onClick={onClose}
                    colorScheme="twitter"
                    title={`I just bid ${bidValue} $GLMR on Teddy ${teddyId} for ${
                      `${charityName} Charity` || "a charity"
                    }`}
                  >
                    Share on Twitter
                  </Button>
                </Box>
              </HStack>
            </Box>
            <Lottie
              animationData={confetti}
              autoplay={true}
              lottieRef={ref}
              loop={false}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

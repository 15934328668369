import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  Text,
  Box,
  List,
  ListItem,
  IconButton,
  Flex,
  Button,
  HStack,
} from "@chakra-ui/react";
import Lottie, { LottieRef } from "lottie-react";
import { BigNumber as EthersBN } from "ethers";
import { Link } from "react-router-dom";


import confetti from "../../assets/lottie/confetti-2.json";
import { getTeddy } from "../StandaloneTeddy";
import { useTeddySeed } from "../../wrappers/teddyToken";
import Teddy from "../Teddy";
import { useEffect, useRef, useState } from "react";
import { ExternalURL, externalURL } from "../../utils/externalURL";
import { GiBearFace } from "react-icons/gi";
import { FaDiscord } from "react-icons/fa";

type ModalWrapperType = {
  isOpen: boolean;
  onClose: () => void;
  teddyId: EthersBN;
};

export const YouWonModal = ({ isOpen, onClose, teddyId }: ModalWrapperType) => {
  const seed = useTeddySeed(teddyId);
  const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
      if (isOpen) {
        setIsLoaded(true);
      } else {
        setIsLoaded(false);
      }
    }, [isOpen]);
    
  const teddyObject = teddyId && seed && getTeddy(teddyId, seed);
  const image = teddyObject?.image
  const description = teddyObject?.description

  const ref: LottieRef = useRef(null);

  const teddy = image && description && <Teddy imgPath={image} alt={description} />;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent
          maxWidth="lg"
          position="relative"
          bg="brand.100"
          color="brand.500"
          overflow="hidden"
        >
          <ModalCloseButton onClick={onClose} zIndex={1} />
          <ModalHeader py={8} textAlign="center">
            <Heading as="h5" fontSize="3xl" color="#ED7614">
              Congratulations!
            </Heading>
            <Text fontWeight="bold" fontSize="lg">
              You're now a proud owner of Teddy #{teddyId.toString()}
            </Text>
          </ModalHeader>

          <ModalBody px={8} py={8} zIndex={1}>
            <Box
              position="relative"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box height="200px" width="200px">
                {teddy}
              </Box>
            </Box>
            <Flex>
              <IconButton
                onClick={() => {
                  if (ref.current) {
                    console.log("its working", ref.current);
                    ref.current.goToAndPlay(0);
                  }
                }}
                _active={{
                  bg: `unset`,
                  p: {
                    transform: "scale(1) !important",
                  },
                }}
                aria-label="throw confetti"
                icon={<Text>🎉</Text>}
                variant="ghost"
                _hover={{
                  p: {
                    transform: "scale(1.5)",
                  },
                }}
              />
            </Flex>
            <Text>
              Now that you have won the auction, you can now do any of these
              things.
            </Text>
            <List
              fontSize="md"
              alignItems="flex-start"
              fontWeight="bold"
              pt={8}
            >
              <ListItem>
                - Download the PNG file of your Teddy for personal use
              </ListItem>
              <ListItem>
                - Visit 'My Teddies' to download receipt for your taxes
              </ListItem>
              <ListItem>
                - Join the Discord community if you haven't already
              </ListItem>
              <HStack justifyContent="center" mt={4}>
                <Button
                  leftIcon={<GiBearFace />}
                  variant="brand"
                  as={Link}
                  to="/my-teddies"
                  width="100%"
                >
                   My Teddies
                </Button>
                <Button
                  leftIcon={<FaDiscord />}
                  variant="brand"
                  as="a"
                  href={externalURL(ExternalURL.discord)}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  width="100%"
                >
                  Join Discord
                </Button>
              </HStack>
            </List>
          </ModalBody>
          {isLoaded && (
            <Box height="300px" position="absolute" zIndex={0}>
              <Lottie
                animationData={confetti}
                autoplay={true}
                lottieRef={ref}
                loop={false}
              />
            </Box>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

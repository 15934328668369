import clsx from "clsx";
import { Image } from "react-bootstrap";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Heading,
  Box,
  Text,
} from "@chakra-ui/react";

import classes from "./NavGetGLMR.module.css";

import talisman from "../../assets/wallet-brand-assets/talisman.svg";
import stellaSwap from "../../assets/stellaswap.svg";
import kucoinLogo from "../../assets/kucoin-logo.svg";
import { Button } from "@chakra-ui/react";

type NavGetGLMRProps = {
  size?: string;
  onClick?: () => void;
}

export const NavGetGLMR = ({ size = "md", onClick }: NavGetGLMRProps) => {
  const modalDisclosure = useDisclosure();
  return (
    <div>
      <Button
        variant="brand"
        size={size}
        width="128px"
        onClick={() => {
          onClick && onClick();
          modalDisclosure.onOpen();
        }}
        className={clsx(classes.getGlmrButton)}
      >
        Get GLMR
      </Button>
      {modalDisclosure.isOpen && (
        <Modal
          onClose={modalDisclosure.onClose}
          isOpen={modalDisclosure.isOpen}
          isCentered
        >
          <ModalOverlay />
          <ModalContent maxWidth="lg">
            <ModalCloseButton />
            <ModalBody>
              <Content />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

const Content = () => {
  return (
    <Box p={4}>
      <Heading
        as="h3"
        textAlign="center"
        style={{ fontWeight: `bold`, color: `var(--brand-dark-background)` }}
      >
        Get GLMR
      </Heading>
      <hr style={{ marginBottom: `16px`, marginTop: `16px` }} />

      <div style={{ marginBottom: `32px` }}>
        <Text textAlign="center">
          You can get GLMR from these exchanges. Please check their terms and
          conditions before buying/trading GLMR.
        </Text>
      </div>
      <div style={{ display: `grid`, gridGap: `16px` }}>
        <div
          style={{ border: `2px solid`, padding: `16px`, borderRadius: `8px` }}
        >
          <h5
            style={{
              fontWeight: `bold`,
              textAlign: "center",
              marginBottom: `8px`,
            }}
          >
            With Fiat/Credit Card
          </h5>
          <a
            href="https://talisman.xyz"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <Image
              src={talisman}
              alt="talisman"
              width="200px"
              style={{ margin: `0 auto` }}
            />
          </a>
        </div>
        <div
          style={{ border: `2px solid`, padding: `16px`, borderRadius: `8px` }}
        >
          <h5
            style={{
              fontWeight: `bold`,
              textAlign: "center",
              marginBottom: `8px`,
            }}
          >
            On Exchanges
          </h5>
          <div
            style={{
              display: `grid`,
              gridTemplateColumns: `1fr 1fr`,
              gridGap: `32px`,
              justifyContent: `center`,
              alignItems: `center`,
              width: `90%`,
              margin: `0 auto`,
              minHeight: `50px`,
            }}
          >
            <a
              href="https://app.stellaswap.com/exchange/swap"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              <Image src={stellaSwap} alt="stella swap" width="100%" />
            </a>
            <a
              href="https://www.kucoin.com/trade/GLMR-USDT"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              <Image src={kucoinLogo} alt="talkucoinisman" width="100%" />
            </a>
          </div>
        </div>
      </div>
    </Box>
  );
};

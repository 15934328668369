"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractsForChainOrThrow = void 0;
const contracts_1 = require("@luckyfriday/contracts");
const addresses_1 = require("./addresses");
/**
 * Get contract instances that target the Ethereum mainnet
 * or a supported testnet. Throws if there are no known contracts
 * deployed on the corresponding chain.
 * @param chainId The desired chain id
 * @param signerOrProvider The ethers v5 signer or provider
 */
const getContractsForChainOrThrow = (chainId, signerOrProvider) => {
    const addresses = (0, addresses_1.getContractAddressesForChainOrThrow)(chainId);
    return {
        teddyTokenContract: contracts_1.TeddyTokenFactory.connect(addresses.teddyToken, signerOrProvider),
        teddyAuctionHouseContract: contracts_1.TeddyAuctionHouseFactory.connect(addresses.teddyAuctionHouseProxy, signerOrProvider),
        teddyDescriptorContract: contracts_1.TeddyDescriptorFactory.connect(addresses.teddyDescriptor, signerOrProvider),
        teddySeederContract: contracts_1.TeddySeederFactory.connect(addresses.teddySeeder, signerOrProvider),
    };
};
exports.getContractsForChainOrThrow = getContractsForChainOrThrow;

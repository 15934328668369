import { ImageData } from '@luckyfriday/assets';
import { Seed } from '../../functions/theGraph'

export interface Attribute {
  trait_type: string;
  value: string | number;
}

export const getAttributesFromSeed = (seed: Seed) : Attribute[] => {
  return [
    {
      trait_type: "Body",
      value: ImageData.images.bodies[seed.body].filename,
    },
    {
      trait_type: "Body Accessory",
      value: ImageData.images.bodyAccessories[seed.bodyAccessory].filename,
    },
    {
      trait_type: "Face",
      value: ImageData.images.faces[seed.face].filename,
    },
    {
      trait_type: "Head Accessory",
      value: ImageData.images.headAccessories[seed.headAccessory].filename,
    },
  ];
}
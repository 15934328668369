import { Box, Input, List, ListItem, Button, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "../../wrappers/supabaseClient";

const fetchCharities = async (query: string) => {
  let { data } = await supabase
    .from("AllCharities")
    .select("Fundraisername, CauseId, MissionStatement, JGURL, LogoDownloadUrl")
    .textSearch("Fundraisername", `'${query}'`);

  return data;
};

type CharitySearchProps = {
  searchTerm: string;
  onChange: (search: string) => void;
};

export const CharitySearch = ({ searchTerm, onChange }: CharitySearchProps) => {
  return (
    <Box>
      <Input
        value={searchTerm}
        onChange={(e) => onChange && onChange(e.target.value)}
        placeholder="Search for a charity"
        borderRadius={4}
        tabIndex={-1}
        autoFocus
        borderWidth="2px"
      />
      {/**
         * Hide combobox for now
         {charitiesQuery.isSuccess && (
        <List
          as="ul"
          position="relative"
          overflowY="scroll"
          height="250px"
          width="100%"
          borderBottomLeftRadius={4}
          borderBottomRadius={4}
        >
          {charitiesQuery.data && charitiesQuery.data.map((charity) => (
            <ListItem key={charity.CauseId} borderRadius={0}>
              <Button
                onClick={() => {
                  onChange && onChange(charity.Fundraisername);
                }}
                width="100%"
                justifyContent="flex-start"
                alignItems="center"
                borderRadius={0}
                py={2}
                height="auto"
              >
                <Box mr={2}>
                  <Text as="span" fontSize="md">
                    {charity.Fundraisername}
                  </Text>
                </Box>
              </Button>
            </ListItem>
          ))}
        </List>
      )}
         */}
    </Box>
  );
};

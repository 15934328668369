import { ReactChildren } from "../../utils/types";
import classes from "./AuctionActivityWrapper.module.css";

const AuctionActivityWrapper: React.FC<{
  className?: string | string[];
  children: ReactChildren;
}> = (props) => {
  return <div className={classes.wrapper}>{props.children}</div>;
};
export default AuctionActivityWrapper;

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  Text,
  Box,
  Image,
  VStack,
} from "@chakra-ui/react";
import { motion } from 'framer-motion'
import { ImageData } from "@luckyfriday/assets";
import { buildSVG } from "@luckyfriday/sdk";
import { BigNumber } from "ethers";
import { useState } from "react";
import { Seed } from "../../../functions/theGraph";
import { getAttributesFromSeed } from "../../utils/getAttributesFromSeed";
import { useTeddySeed } from "../../wrappers/teddyToken";

export const AttributesModal = ({
  onClose,
  isOpen,
  teddyId
} : {
  onClose: () => void;
  isOpen: boolean;
  teddyId: BigNumber | undefined;
}) => {

  const seed: Seed = useTeddySeed(teddyId!);

  const [activeTrait, setActiveTrait] = useState(0);

  const body = `data:image/svg+xml;base64,${btoa(
    buildSVG(
      [
        {
          data: ImageData.images.bodies[seed.body].data,
        },
      ],
      ImageData.palette,
      ""
    )
  )}`;

  const bodyAccessory = `data:image/svg+xml;base64,${btoa(
    buildSVG(
      [
        {
          data: ImageData.images.bodyAccessories[seed.bodyAccessory].data,
        },
      ],
      ImageData.palette,
      ""
    )
  )}`;

  const headAccessory = `data:image/svg+xml;base64,${btoa(
    buildSVG(
      [
        {
          data: ImageData.images.headAccessories[seed.headAccessory].data,
        },
      ],
      ImageData.palette,
      ""
    )
  )}`;

    const face = `data:image/svg+xml;base64,${btoa(
      buildSVG(
        [
          {
            data: ImageData.images.faces[seed.face].data,
          },
        ],
        ImageData.palette,
        ""
      )
    )}`;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent
          maxWidth="xl"
          position="relative"
          overflow="hidden"
        >
          <ModalCloseButton onClick={onClose} zIndex={1} />
          <ModalHeader py={8} textAlign="center">
            <Heading
              as="h5"
              fontSize="3xl"
              color="#ED7614"
              fontWeight="bold"
              fontFamily="heading"
            >
              Teddy Traits
            </Heading>
          </ModalHeader>

          <ModalBody
            px={8}
            py={8}
            zIndex={1}
            display={["block", "block", "block", "grid"]}
            gridTemplateColumns="1fr 1fr"
            justifyContent="center"
          >
            <Box
              position="relative"
              height="250px"
              width="250px"
              margin="0 auto"
            >
              {body && (
                <motion.div
                  animate={{
                    opacity: activeTrait === 3 ? 1 : 0.25,
                  }}
                >
                  <Image position="absolute" src={body} alt="body" />
                </motion.div>
              )}
              {face && (
                <motion.div
                  animate={{
                    opacity: activeTrait === 1 ? 1 : 0.25,
                  }}
                >
                  <Image position="absolute" src={face} alt="face accessory" />
                </motion.div>
              )}
              {bodyAccessory && (
                <motion.div
                  animate={{
                    opacity: activeTrait === 2 ? 1 : 0.25,
                  }}
                >
                  <Image
                    position="absolute"
                    src={bodyAccessory}
                    alt="body accessory"
                  />{" "}
                </motion.div>
              )}
              {headAccessory && (
                <motion.div
                  animate={{
                    opacity: activeTrait === 0 ? 1 : 0.25,
                  }}
                >
                  <Image
                    position="absolute"
                    src={headAccessory}
                    alt="head accessory"
                  />
                </motion.div>
              )}
            </Box>
            <VStack>
              {seed &&
                getAttributesFromSeed(seed)
                  .reverse()
                  .map((attribute, id) => {
                    const isActive = activeTrait === id;
                    return (
                      <Box
                        key={id}
                        as="button"
                        onClick={() => {
                          setActiveTrait(id);
                        }}
                        textAlign="center"
                        p={2}
                        width="200px"
                        borderRadius="4px"
                        sx={{
                          bg: isActive ? `brand.500` : `unset`,
                          color: isActive ? `white` : `inherit`,
                        }}
                        border="1px solid"
                        borderColor="brand.500"
                      >
                        <Text fontSize="sm" lineHeight={1} mb={0}>
                          {attribute.trait_type}:{" "}
                        </Text>
                        <Text
                          fontWeight="bold"
                          fontSize="lg"
                          lineHeight={1}
                          mb={0}
                        >
                          {attribute.value}
                        </Text>
                      </Box>
                    );
                  })}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

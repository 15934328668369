import { TalismanConnector } from "@talismn/web3react-v6-connector";
import { useEthers } from "@usedapp/core";
import { useCallback, useEffect, useState } from "react";
import { useLocalStorage } from "react-use";

import { CHAIN_ID } from "../config";

export const usePreferredConnector = () => {
  const [preferredConnector, setPreferredConnector] = useLocalStorage(
    "connector",
    ""
  );

  const clearPreferredConnector = useCallback(() => {
    setPreferredConnector("");
  }, [setPreferredConnector]);

  return { preferredConnector, setPreferredConnector, clearPreferredConnector };
};

const useAutoConnect = () => {
  const { preferredConnector } = usePreferredConnector();

  const { library, activate, activateBrowserWallet } = useEthers();
  const [triedAutoConnect, setTriedAutoConnect] = useState(false);

  // auto connect
  useEffect(() => {
    // try only once
    if (triedAutoConnect) return;
    setTriedAutoConnect(true);

    try {
      // if connected or if no preferred connector, we can't auto connect
      if (!preferredConnector) return;

      // activate preferred connector
      if (preferredConnector === "talisman") {
        const talisman = new TalismanConnector({
          supportedChainIds: [CHAIN_ID],
        });
        activate(talisman);
      } else if (preferredConnector === "metamask") {
        // if eth_accounts returns an account, it's unlocked.
        // if not, we can't auto connect without displaying authentication popup
        // @ts-ignore
        window.ethereum?.request({ method: "eth_accounts" }).then((result) => {
          if (result?.[0]) activateBrowserWallet();
        });
      }

      // Note : no auto connect on WalletConnect because it requires user interaction
    } catch (err) {
      console.error("failed to auto connect", err);
    }
  }, [
    activate,
    activateBrowserWallet,
    library,
    preferredConnector,
    triedAutoConnect,
  ]);
};

// reauthenticates user automatically if they F5 / refresh the page.
export const AutoConnect = ({ children }: { children: JSX.Element }) => {
  useAutoConnect();

  return children;
};

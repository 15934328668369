import { useContractCall } from "@usedapp/core";
import { BigNumber as EthersBN, utils } from "ethers";
import { TeddyAuctionHouseABI } from "@luckyfriday/sdk";
import config from "../config";
import { useAppSelector } from "../hooks";
import { AuctionState } from "../state/slices/auction";

export enum AuctionHouseContractFunction {
  auction = "auction",
  duration = "duration",
  minBidIncrementPercentage = "minBidIncrementPercentage",
  teddy = "teddy",
  createBid = "createBid",
  settleCurrentAndCreateNewAuction = "settleCurrentAndCreateNewAuction",
}

export interface Auction {
  amount: EthersBN;
  bidder: string;
  endTime: EthersBN;
  startTime: EthersBN;
  teddyId: EthersBN;
  settled: boolean;
}

const abi = new utils.Interface(TeddyAuctionHouseABI.abi);

export const useAuction = (auctionHouseProxyAddress: string) => {
  const auction = useContractCall<Auction>({
    abi,
    address: auctionHouseProxyAddress,
    method: "auction",
    args: [],
  });
  return auction as Auction;
};

export const useAuctionMinBidIncPercentage = () => {
  const minBidIncrement = useContractCall({
    abi,
    address: config.addresses.teddyAuctionHouseProxy,
    method: "minBidIncrementPercentage",
    args: [],
  });
  if (!minBidIncrement) {
    return;
  }

  return EthersBN.from(minBidIncrement[0]);
};

/**
 * Computes timestamp after which a Teddy could vote
 * @param teddyId TokenId of Teddy
 * @returns Unix timestamp after which Teddy could vote
 */
export const useTeddyCanVoteTimestamp = (teddyId: number) => {
  const nextTeddyId = teddyId + 1;

  const nextTeddyIdForQuery = nextTeddyId;

  const pastAuctions = useAppSelector(
    (state) => state.pastAuctions.pastAuctions
  );

  const maybeTeddyCanVoteTimestamp = pastAuctions.find(
    (auction: AuctionState, i: number) => {
      const maybeTeddyId = auction.activeAuction?.teddyId;
      return maybeTeddyId
        ? EthersBN.from(maybeTeddyId).eq(EthersBN.from(nextTeddyIdForQuery))
        : false;
    }
  )?.activeAuction?.startTime;

  if (!maybeTeddyCanVoteTimestamp) {
    // This state only occurs during loading flashes
    return EthersBN.from(0);
  }

  return EthersBN.from(maybeTeddyCanVoteTimestamp);
};
